import React, { Component } from 'react';
import './Header.css';
import {Container, Row, Col, Modal} from 'react-bootstrap';
import {withRouter} from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import expatLogo from '../../assets/images/expat-logo.svg';
import thankYouImg from "../../assets/images/thank-you.png";
import {connect} from "react-redux";
import {isMobile} from 'react-device-detect';
import {
    emailInputValidation, embedHubSpotInitScripts,
    textInputValidation
} from "../../global-app-elements/global_helper_functions";
import {
    changeSlideInModalVisibility,
    clearComparePlansServerArrayBeforeFetching,
    sendUserContactFormDataToEmail
} from "../../actions/actions";
import {Trans} from 'react-i18next';
import i18n from "i18next";
import {GAevent, modalViewGA} from "../../global-app-elements/ga-functions";
import paulinaPhoto from "../../assets/images/flavie.png";
import {SLIDE_IN_MODAL_DELAY_IN_SECONDS} from "../../global-app-elements/global-constants";





class Header extends Component {


    constructor(props) {

        super(props);
        this.state = {
            user_personal_information: {
                contact_information_object: {
                    full_name: null,
                    email: null,
                    phone: null,
                    message: null
                }
            },
            form_validation_error_detected: false,
            is_modal_visible: false,
            modal_content_type: null,
            is_slide_in_modal_visible: false
        };

        this.handleGlobalContactForm = this.handleGlobalContactForm.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChangeContactInformationFullName = this.handleChangeContactInformationFullName.bind(this);
        this.handleChangeContactInformationEmail = this.handleChangeContactInformationEmail.bind(this);
        this.handleChangeContactInformationPhone = this.handleChangeContactInformationPhone.bind(this);
        this.handleChangeContactInformationMessage = this.handleChangeContactInformationMessage.bind(this);
        this.handleEtreRappele = this.handleEtreRappele.bind(this);
        this.handleCloseSlideInModal = this.handleCloseSlideInModal.bind(this);
        this.handleShowSlideInModal = this.handleShowSlideInModal.bind(this);
    }



    componentDidUpdate(prevProps, prevState) {

        // Якщо користувач оновив Travel info data в Global Store то слід зробити новий запит до API
        if (prevProps.compare_plans_local_array !== this.props.compare_plans_local_array) {

            this.props.onClearComparePlansServerArrayBeforeFetching();

        }



        // код відповідає за показ Slide In Modal після загрузки результатів
        const gate_closed = localStorage.getItem('gate_closed');

        if ( this.props.result_insurance_plans_array && gate_closed === 'false' && this.props.slide_in_modal_visible ) {

            // помічаю в Global Store прапорець , щоб більше за сесію не відкривалося
            this.props.onChangeSlideInModalVisibility();

            let then = this;

            setTimeout(function () {

                then.handleShowSlideInModal();

            }, SLIDE_IN_MODAL_DELAY_IN_SECONDS );

        }



        // заборонити скролл body , якщо відкрита slide in modal
        if ( isMobile ) {

            if ( this.state.is_slide_in_modal_visible ) {

                document.body.style.overflow = 'hidden';

            } else {

                document.body.style.overflow = 'initial';

            }

        }

    }


    handleGlobalContactForm() {

        // TODO: Зробити толкову валідацію
        let not_valid_inputs_array = document.querySelectorAll('.contact-form-centered-modal .not-valid-input');


        if ( not_valid_inputs_array.length > 0 ) {

            this.setState({ form_validation_error_detected: true });

            not_valid_inputs_array[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});

        } else {

            let insurees_data = this.props.insurees_data;

            let data_for_sending = {
                user_personal_information: this.state.user_personal_information.contact_information_object,
                insurees_data: insurees_data
            }

            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', window.location.href]);
            _hsq.push(["identify",{
                firstname: 'converted in app',
                lastname: 'contact-us '  + data_for_sending.user_personal_information.full_name,
                email: data_for_sending.user_personal_information.email,
                phone: data_for_sending.user_personal_information.phone,
                commentaires: data_for_sending.user_personal_information.message ,
                dur_e_du_contrat_souhait_: data_for_sending.insurees_data.insurance_duration,
                adh_rent_cfe: data_for_sending.insurees_data.insuree_has_cfe,
                leadin_pays_de_destination_bc1e211f71cc3f2e8346f8719273fb80: data_for_sending.insurees_data.destination_country,
                leadin_pays_de_nationalitswb_0b819286eb1d5bab2234c31836f1e050: data_for_sending.insurees_data.insuree_object.nationality,
                user_age_from_search: data_for_sending.insurees_data.insuree_object.age,
                dependents_array: JSON.stringify(data_for_sending.insurees_data.dependents_array)
            }]);
            _hsq.push(['trackPageView']);

            this.props.onSendUserContactFormDataToEmail(data_for_sending);
            this.handleCloseModal();
            GAevent("contactFormSubmitted", "comparateur");
            this.handleShowModal('thank-you-form');

        }

    }


    handleEtreRappele() {

        this.handleShowModal('etre-rappele');

    }


    handleShowModal(content_type) {

        this.setState({
            is_modal_visible: true,
            modal_content_type: content_type
        });

        // TRIGGER MODAL VIEW FOR GOOGLE ANALYTICS
        modalViewGA(content_type);

    }


    handleCloseModal() {

        this.setState({
            is_modal_visible: false,
            modal_content_type: null
        });

    }


    handleChangeContactInformationFullName(changed_value) {

        let input_value = changed_value.target.value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    full_name: input_value
                }
            }
        }));

    };


    handleChangeContactInformationEmail(changed_value) {

        let input_value = changed_value.target.value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    email: input_value
                }
            }
        }));

    };


    handleChangeContactInformationPhone(changed_value) {

        let input_value = changed_value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    phone: input_value
                }
            }
        }));

    };


    handleChangeContactInformationMessage(changed_value) {

        let input_value = changed_value.target.value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    message: input_value
                }
            }
        }));

    };


    handleCloseSlideInModal() {

        this.setState({
            is_slide_in_modal_visible: false
        });

    }


    handleShowSlideInModal() {

        this.setState({
            is_slide_in_modal_visible: true
        });

    }


    renderContactButtons() {

        return (

            <>
                <button
                    className="secondaryButtonStyle etreRappeleButton"
                    onClick={this.handleEtreRappele}>
                    <Trans i18nKey={"coveregePage_call_request"}/> <span className="icon-phone-2-fill"></span>
                </button>

                <button className={`secondaryButtonStyle headerContactFormButton ${(this.props.compare_plans_local_array.length > 0) ? 'is-visible' : null}`} onClick={this.handleShowModal.bind(this, 'contact-form')}>
                    <span className="icon-mail"></span>
                    <span className="headerContactFormButtonText"><Trans i18nKey="coveregePage_contact_us"></Trans></span>
                </button>

            </>

        )

    }


    renderModalContent() {

        let contact_information_object = this.state.user_personal_information.contact_information_object;

        switch ( this.state.modal_content_type ) {

            case 'etre-rappele':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="full-window-modal">
                        <Modal.Header closeButton>
                            <Container className="defaultContainer">
                                <button className="close-modal-button" variant="secondary" onClick={this.handleCloseModal}>
                                    <span className="icon-big-arrow-left"></span>
                                    <span className="close-modal-button-title"><Trans i18nKey={"getPlanePage_back_arrow"}/></span>
                                </button>
                                <Modal.Title><Trans i18nKey={"coveregePage_call_request"}/></Modal.Title>
                            </Container>
                        </Modal.Header>
                        <Modal.Body className="etreRappeleModalBody">
                            <Container className="defaultContainer">

                                <div className="etreRappeleModalText">
                                    <Trans i18nKey={"coveregePage_callback_modal_text"}/>
                                </div>

                                <div
                                    className="meetings-iframe-container"
                                    data-src="https://app.hubspot.com/meetings/pauline43/expat-assurance?embed=true"></div>

                                { embedHubSpotInitScripts() }

                            </Container>
                        </Modal.Body>
                    </Modal>

                );

            case 'contact-form':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        // backdrop="static"
                        keyboard={false}
                        centered={true}
                        dialogClassName="contact-form-centered-modal">
                        <Modal.Header closeButton>
                            <button className="close-modal" onClick={this.handleCloseModal}>
                                <span className="icon-close"></span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>

                            <div className={`userDataCard contactInformationCard`+" "+process.env.REACT_APP_LANGUAGE+`_reset_style_class ${(this.state.form_validation_error_detected) ? "form-validation-error" : null }`}>
                                <div className="userDataCardTitle"><span className="icon-message-circle"></span> <Trans i18nKey="getPlanePage_subscribe_form_title"/></div>
                                <div className="userDataCardSubTitle"><Trans i18nKey="getPlanePage_subscribe_form_subtitle"/></div>

                                <Row>

                                    <Col xs={12}>
                                        <div className={`inputBox ${(textInputValidation(contact_information_object.full_name)) ? "is-valid-input" : "not-valid-input" }`}>
                                            <label><Trans i18nKey="getPlanePage_your_contacts_name"/><span className="red">*</span></label>
                                            <input
                                                type="text"
                                                placeholder={i18n.t("global_your_name_placeholder")}
                                                defaultValue={contact_information_object.full_name}
                                                onChange={this.handleChangeContactInformationFullName}
                                            />
                                        </div>
                                    </Col>

                                </Row>

                                <Row>

                                    <Col xs={6}>
                                        <div className={`inputBox ${(emailInputValidation(contact_information_object.email)) ? "is-valid-input" : "not-valid-input" }`}>
                                            <label><Trans i18nKey="getPlanePage_subscribe_form_email"/><span className="red">*</span></label>
                                            <input
                                                type="text"
                                                placeholder={i18n.t("global_your_email_placeholder")}
                                                defaultValue={contact_information_object.email}
                                                onChange={this.handleChangeContactInformationEmail}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs={6}>
                                        <div className={`inputBox ${(contact_information_object.phone) ? "is-valid-input" : "not-valid-input" }`}>
                                            <label><Trans i18nKey="getPlanePage_subscribe_form_phone"/><span className="red">*</span></label>
                                            <PhoneInput
                                                country={'fr'}
                                                placeholder="+33 1 09 75 83 51"
                                                defaultValue={contact_information_object.phone}
                                                onChange={this.handleChangeContactInformationPhone}
                                            />
                                        </div>
                                    </Col>

                                </Row>

                                <Row>

                                    <Col xs={12}>
                                        <div className={`inputBox ${(textInputValidation(contact_information_object.message)) ? "is-valid-input" : null }`}>
                                            <label><Trans i18nKey="getPlanePage_subscribe_form_message" components={{i:<span/>}} /></label>
                                            <textarea
                                                defaultValue={contact_information_object.message}
                                                onChange={this.handleChangeContactInformationMessage}
                                            ></textarea>
                                        </div>
                                    </Col>

                                </Row>

                                <Row className="bottomRow">
                                    <Col xs={9} className="legalCol">
                                        <p>
                                            <Trans i18nKey="legal_text"/> <a href={i18n.t("legal_link")} target="_blank"> <Trans i18nKey="legal_link_text"/></a>.
                                        </p>
                                    </Col>

                                    <Col xs={3}>
                                        <button className="mainButtonStyle handleReceiveACall" onClick={this.handleGlobalContactForm}>
                                            <Trans i18nKey="header_send_to_text" /> <span className="icon-big-arrow-right"></span>
                                        </button>
                                    </Col>

                                </Row>

                            </div>

                        </Modal.Body>
                    </Modal>

                );

            case 'thank-you-form':
                return (
                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        // backdrop="static"
                        keyboard={false}
                        centered={true}
                        dialogClassName="contact-form-centered-modal">
                        <Modal.Header closeButton>
                            <button className="close-modal" onClick={this.handleCloseModal}>
                                <span className="icon-close"></span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="thankYouPageBlock thankYouInsurancePage">
                                <div className="thankYouPageIcon"><img src={thankYouImg} alt="Expat Assurance"/></div>
                                <div className="thankYouPageTitle"><Trans i18nKey="thankYouPage_title"/> !</div>
                                <div className="thankYouPageDescription">
                                    <Trans i18nKey="thankYouPage_subtitle"/> <button onClick={this.handleEtreRappele}><Trans i18nKey="thankYouPage_link_text"/></button>
                                </div>
                                <div className="thankYouPageButton">
                                    <button onClick={this.handleCloseModal} className="mainButtonStyle"><Trans i18nKey="thankYouPage_submit_return_button_text"/></button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                );

            default:

                return null

        }

    }


    renderHeaderType() {

        switch (this.props.location.pathname) {
            case '/':
                return (

                    <div className="header mainPageHeaderStyle">

                        <Container className="headerContainer">

                            <Row>
                                <Col xs={12} className="expatLogoBlock"><img src={expatLogo} alt="Expat Assurance" /></Col>
                            </Row>

                        </Container>

                    </div>

                );

            case '/insurance':

                return (

                    <div className="header defaultHeaderStyle">

                        <Container className="headerContainer">

                            <Row>
                                <Col xs={6} className="appLogoBlock">
                                    <img src={expatLogo} alt="Expat Assurance" />
                                </Col>
                                <Col xs={6} className="headerButtonsPart">
                                    { this.renderContactButtons() }
                                </Col>
                            </Row>

                        </Container>

                        {/*  MODALS PART  */}
                        { this.renderModalContent() }


                        {/*  SLIDE IN MODAL  */}
                        <div className={`slideInModalBlockOverlayForMobile ${(this.state.is_slide_in_modal_visible) ? 'is-visible' : null }`}></div>

                        <div className={`slideInModalBlock ${(this.state.is_slide_in_modal_visible) ? 'is-visible' : null }`}>
                            <div className="slideInModalBody">
                                <div className="slideInModalAvatar">
                                    <img src={paulinaPhoto} alt="Paulina"/>
                                </div>

                                <div className="slide-modal-animate-wrapper-1">
                                    <div className="slideInModalMessage">
                                        <p>
                                            <Trans i18nKey={"header_slide_modal_message_1"}/>
                                        </p>

                                        <p>
                                            <Trans i18nKey={"header_slide_modal_message_2"}/>
                                        </p>

                                        <p>
                                            <Trans i18nKey={"header_slide_modal_message_3"}/> :
                                        </p>
                                    </div>
                                </div>

                                <div className="slideInModalButtonsBlock">

                                    <div className="slide-modal-animate-wrapper-2">
                                        <button className="slideInModalHandlerButton slideInModalHandlerButtonOne" onClick={this.handleEtreRappele}>
                                            <span className="slideInModalHandlerButtonBody">
                                                <Trans i18nKey={"header_slide_modal_button_1"}/>
                                            </span>
                                            <span className="icon-arrow-long-right"></span>
                                        </button>
                                    </div>

                                    <div className="slide-modal-animate-wrapper-3">
                                        <button className="slideInModalHandlerButton slideInModalHandlerButtonTwo" onClick={this.handleShowModal.bind(this, 'contact-form')}>
                                            <span className="slideInModalHandlerButtonBody">
                                                <Trans i18nKey={"header_slide_modal_button_2"}/>
                                            </span>
                                            <span className="icon-arrow-long-right"></span>
                                        </button>
                                    </div>

                                    <div className="slide-modal-animate-wrapper-4">
                                        <button className="slideInModalCloseButton" onClick={this.handleCloseSlideInModal}>
                                            <span className="slideInModalHandlerButtonBody">
                                                <Trans i18nKey={"header_slide_modal_button_3"}/>
                                            </span>
                                            <span className="icon-close"></span>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                );

            default:
                return (

                    <div className="header defaultHeaderStyle">

                        <Container className="headerContainer">

                            <Row>
                                <Col xs={6} className="appLogoBlock"><img src={expatLogo} alt="Expat Assurance" /></Col>
                                <Col xs={6} className="headerButtonsPart">

                                    <button className="secondaryButtonStyle headerContactFormButton" onClick={this.handleShowModal.bind(this, 'contact-form')}>
                                        <span className="icon-mail"></span>
                                        <span className="headerContactFormButtonText"><Trans i18nKey={"coveregePage_contact_us"}/></span>
                                    </button>

                                </Col>
                            </Row>

                        </Container>

                        {/*  MODALS PART  */}
                        { this.renderModalContent() }


                        {/*  SLIDE IN MODAL  */}
                        <div className={`slideInModalBlockOverlayForMobile ${(this.state.is_slide_in_modal_visible) ? 'is-visible' : null }`}></div>

                        <div className={`slideInModalBlock ${(this.state.is_slide_in_modal_visible) ? 'is-visible' : null }`}>
                            <div className="slideInModalBody">
                                <div className="slideInModalAvatar">
                                    <img src={paulinaPhoto} alt="Paulina"/>
                                </div>

                                <div className="slide-modal-animate-wrapper-1">
                                    <div className="slideInModalMessage">
                                        <p>
                                            <Trans i18nKey={"header_slide_modal_message_1"}/>
                                        </p>

                                        <p>
                                            <Trans i18nKey={"header_slide_modal_message_2"}/>
                                        </p>

                                        <p>
                                            <Trans i18nKey={"header_slide_modal_message_3"}/> :
                                        </p>
                                    </div>
                                </div>

                                <div className="slideInModalButtonsBlock">

                                    <div className="slide-modal-animate-wrapper-2">
                                        <button className="slideInModalHandlerButton slideInModalHandlerButtonOne" onClick={this.handleEtreRappele}>
                                            <span className="slideInModalHandlerButtonBody">
                                                <Trans i18nKey={"header_slide_modal_button_1"}/>
                                            </span>
                                            <span className="icon-arrow-long-right"></span>
                                        </button>
                                    </div>

                                    <div className="slide-modal-animate-wrapper-3">
                                        <button className="slideInModalHandlerButton slideInModalHandlerButtonTwo" onClick={this.handleShowModal.bind(this, 'contact-form')}>
                                            <span className="slideInModalHandlerButtonBody">
                                                 <Trans i18nKey={"header_slide_modal_button_2"}/> !
                                            </span>
                                            <span className="icon-arrow-long-right"></span>
                                        </button>
                                    </div>

                                    <div className="slide-modal-animate-wrapper-4">
                                        <button className="slideInModalCloseButton" onClick={this.handleCloseSlideInModal}>
                                            <span className="slideInModalHandlerButtonBody">
                                                <Trans i18nKey={"header_slide_modal_button_3"}/>
                                            </span>
                                            <span className="icon-close"></span>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                );
        }

    }



    render() {

        return (<>{ this.renderHeaderType() }</>)

    }


}


export default withRouter(connect(
    state => ({
        insurees_data: state.main_form_data.insurees_data,
        compare_plans_local_array: state.compare_plans_data.local_plans_data,
        result_insurance_plans_array: state.result_insurance_plans.result_insurance_plans_array,
        slide_in_modal_visible: state.app_options.slide_in_modal_visible,
        gating_response: state.app_options.gating_response,
    }),
    dispatch => ({
        onSendUserContactFormDataToEmail: (data_for_sending) => {
            dispatch(sendUserContactFormDataToEmail(data_for_sending));
        },
        onClearComparePlansServerArrayBeforeFetching: () => {
            dispatch(clearComparePlansServerArrayBeforeFetching());
        },
        onChangeSlideInModalVisibility: () => {
            dispatch(changeSlideInModalVisibility());
        }
    })
)(Header));


