//actions types
import {GAevent} from "../global-app-elements/ga-functions";
import {calculateFinalPrice} from "../global-app-elements/global_helper_functions";

export const SAVE_DATA_FROM_MAIN_PAGE_FORM = 'SAVE_DATA_FROM_MAIN_PAGE_FORM';
export const SAVE_INSURANCE_PLANS_FROM_API = 'SAVE_INSURANCE_PLANS_FROM_API';
export const SAVE_INSURANCE_PLAN_EXTENDED_DATA_FROM_API = 'SAVE_INSURANCE_PLAN_EXTENDED_DATA_FROM_API';

export const SAVE_INSURANCE_PLAN_DATA_FOR_GET_PLAN = 'SAVE_INSURANCE_PLAN_DATA_FOR_GET_PLAN';
export const SAVE_INSURANCE_PLAN_DATA_FOR_VIEW_PLAN = 'SAVE_INSURANCE_PLAN_DATA_FOR_VIEW_PLAN';
export const CLEAR_INSURANCE_PLAN_DATA_FOR_VIEW_PLAN = 'CLEAR_INSURANCE_PLAN_DATA_FOR_VIEW_PLAN';

export const SAVE_INSURANCE_PLANS_DATA_FOR_COMPARE_PLANS = 'SAVE_INSURANCE_PLANS_DATA_FOR_COMPARE_PLANS';
export const SAVE_INSURANCE_PLAN_EXTENDED_DATA_FOR_COMPARE_PLANS_FROM_API = 'SAVE_INSURANCE_PLAN_EXTENDED_DATA_FOR_COMPARE_PLANS_FROM_API';

export const CLEAR_COMPARE_PLANS_SERVER_ARRAY_BEFORE_FETCHING = 'CLEAR_COMPARE_PLANS_SERVER_ARRAY_BEFORE_FETCHING';
export const CLEAR_INSURANCE_PLANS_ARRAY_BEFORE_FETCHING = 'CLEAR_INSURANCE_PLANS_ARRAY_BEFORE_FETCHING';
export const CHANGE_PAYMENT_FREQUENCY_VALUE = 'CHANGE_PAYMENT_FREQUENCY_VALUE';
export const CHANGE_FILTER_OPTION = 'CHANGE_FILTER_OPTION';



export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_SINGLE_PRODUCT = 'FETCH_SINGLE_PRODUCT';
export const IS_FAIL_FETCH_SINGLE_PRODUCT = 'IS_FAIL_FETCH_SINGLE_PRODUCT';

export const SAVE_USER_ACCOUNT_DATA = 'SAVE_USER_ACCOUNT_DATA';
export const SAVE_GATING_RESPONSE_FROM_API = 'SAVE_GATING_RESPONSE_FROM_API';
export const CHANGE_SLIDE_IN_MODAL_VISIBILITY = 'CHANGE_SLIDE_IN_MODAL_VISIBILITY';


export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';
export const HAS_THE_CURENCY_VALUE_CHANGED = 'HAS_THE_CURENCY_VALUE_CHANGED';

export const CHANGE_SORTING = 'CHANGE_SORTING';

export const CHANGE_SERVER_TIMEOUT_POPUP_VISIBILITY = 'CHANGE_SERVER_TIMEOUT_POPUP_VISIBILITY';






// actions creators
export function saveDataFromMainPageForm (main_form_data_object) {

    return {
        type: SAVE_DATA_FROM_MAIN_PAGE_FORM,
        payload: main_form_data_object
    }

}


export function saveInsurancePlansFromAPI (result_insurance_plans_array) {

    return {
        type: SAVE_INSURANCE_PLANS_FROM_API,
        payload: result_insurance_plans_array
    }

}


export function saveInsurancePlanExtendedDataFromAPI (result_insurance_plan_extended_data) {

    return {
        type: SAVE_INSURANCE_PLAN_EXTENDED_DATA_FROM_API,
        payload: result_insurance_plan_extended_data
    }

}


export function saveInsurancePlanDataForGetPlan (plan_data_object) {

    return {
        type: SAVE_INSURANCE_PLAN_DATA_FOR_GET_PLAN,
        payload: plan_data_object
    }

}


export function saveInsurancePlanDataForViewPlan (plan_data_object) {

    return {
        type: SAVE_INSURANCE_PLAN_DATA_FOR_VIEW_PLAN,
        payload: plan_data_object
    }

}


export function clearInsurancePlanDataForViewPlan () {

    return {
        type: CLEAR_INSURANCE_PLAN_DATA_FOR_VIEW_PLAN
    }

}


export function saveInsurancePlansDataForComparePlans (plans_data_object) {

    return {
        type: SAVE_INSURANCE_PLANS_DATA_FOR_COMPARE_PLANS,
        payload: plans_data_object
    }

}


export function saveInsurancePlansExtendedDataForComparePlansFromAPI (result_insurance_plans_extended_data) {

    return {
        type: SAVE_INSURANCE_PLAN_EXTENDED_DATA_FOR_COMPARE_PLANS_FROM_API,
        payload: result_insurance_plans_extended_data
    }

}



export function clearComparePlansServerArrayBeforeFetching () {

    return {
        type: CLEAR_COMPARE_PLANS_SERVER_ARRAY_BEFORE_FETCHING
    }

}


export function clearInsurancePlansArrayBeforeFetching () {

    return {
        type: CLEAR_INSURANCE_PLANS_ARRAY_BEFORE_FETCHING
    }

}


export function changePaymentFrequencyValue(new_payment_frequency_value) {

    return {
        type: CHANGE_PAYMENT_FREQUENCY_VALUE,
        payload: new_payment_frequency_value
    }

}


export function changeFilterOption(new_filter_option_object) {

    return {
        type: CHANGE_FILTER_OPTION,
        payload: new_filter_option_object
    }

}



export function saveGatingResponseFromAPI (gating_response) {

    return {
        type: SAVE_GATING_RESPONSE_FROM_API,
        payload: gating_response
    }

}



export function changeSlideInModalVisibility () {

    return {
        type: CHANGE_SLIDE_IN_MODAL_VISIBILITY
    }

}


export function changeCurrency(currency) {
    return {
        type: CHANGE_CURRENCY,
        payload: currency
    }

}

export function changeCurrencyChangeValue(value) {

    return {
        type: HAS_THE_CURENCY_VALUE_CHANGED,
        payload: value
    }

}

export function changeServerTimeOutPopupVisibility(visibility) {

    return {
        type: CHANGE_SERVER_TIMEOUT_POPUP_VISIBILITY,
        payload: visibility
    }

}

export function changeSorting(value) {
    return {
        type: CHANGE_SORTING,
        payload: value
    }

}






function fetch_retry(url, options, n, dispatch) {
    return fetch(url, options).then(res => res.json()).catch(function(error) {

        dispatch(changeServerTimeOutPopupVisibility(true));

        if (n === 1) throw error;
        return fetch_retry(url, options, n - 1, dispatch);
    });
}

// connection with API, and getting insurance plans list JSON

export function fetchInsurancePlans(main_form_data_object) {

    return dispatch => {

        // dispatch(saveInsurancePlansFromAPI(result_insurance_plans_array));

        // this request is served by Express hosted in the same heroku container, proxied by React on localhost
        // let fetch_result = fetch("https://hammasoskari-proxy.herokuapp.com/https://comparateur.expat-assurance.com/get-price-data/", {
        let fetch_result = fetch_retry("/get-price-data/", {

            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data:  main_form_data_object
            })
        }, 4, dispatch).then(res => res);

        fetch_result
            .then(json => {

                console.log(json);

                /**
                 * Very not elegant attempt to overcome the deductible bug by setting the deductible value into the plans objects
                 * already on the front-end. It works bulletproof
                 */
                for (let plan of json) {

                    if (main_form_data_object.insuree_has_cfe === "YES") {
                        console.log("Inside insuree_has_cfee===YES now, locking deductible");
                        plan.deductible_options = {
                            "type": "LOCKED",
                            "values": ["100"],
                            "locked_reason": "Le choix du montant de la franchise n’est pas possible pour une assurance complémentaire à la CFE."
                        };
                        //set the selected option to coinsurance automatically, but only for April, other companies won't have coinsurance
                        // and deductible options at the same time. for other companies (so far only MSH - set it to null because no option will be available)
                        if (plan.company === "April")
                            plan.selected_radio_option = "coinsurance";
                        else
                            plan.selected_radio_option = "null";
                    }


                }

                dispatch(saveInsurancePlansFromAPI(json));
                dispatch(changeServerTimeOutPopupVisibility(false));

            });

    }

}



// connection with API, and getting insurance plan extended data JSON

export function fetchInsurancePlanExtendedData(insurance_company, company_guarantee_level, data_type_for_USA_MSH, destination_country) {

    return dispatch => {

        // let result_insurance_plan_extended_data = {
        //     plan_id: "A2",
        //     company: "April",
        //     company_guarantee_level: "ESSENTIELLE",
        //     coverage_sum: "1000000",
        //     coverage_levels: [
        //         {
        //             coverage_level_to_match: "H",
        //             level_name: "Hospitalisation",
        //             level_values: {
        //                 "Chambre d'hopital": "€75.00",
        //                 "Hospitalisation de jour": "100%"
        //             }
        //         },
        //         {
        //             coverage_level_to_match: "RC",
        //             level_name: "Soins Courants",
        //             level_values: {
        //                 "Ostheopatie, chiropractie \n Homeopathie, acupuncture, Med Chin": "100%", //зверни увагу на <br/>
        //                 "Bilan de Sante \n Depistages Cancer": "NON", //а в цьому випадку, якщо щось NON треба завжди перевіряти кожне value if (level_values[key] === "NON") то відображаємо щось захардкоджене що означає "недоступно". Можливо доведеться колись міняти між червоним хрестиком, сірим хрестиком, надписом NON, Not available і т.д. і т.п. і обов'язково таке недоступне значення має бути виділено сірим шрифтом і відрізнятись від інших
        //             }
        //         },
        //         {
        //             coverage_level_to_match: "M",
        //             level_name: "Maternite",
        //             level_values: {
        //                 "Plafond Total Maternite": "NON",
        //                 "Si accouchement Chirurgical": "NON",
        //                 "Plafond complication": "NON",
        //                 "Traitement Sterelite": "NON",
        //             }
        //         }
        //     ]
        // };

        // dispatch(saveInsurancePlanExtendedDataFromAPI(result_insurance_plan_extended_data));

        //todo: host all these files on EA back-end and query EA backend
        // this request is served by Express hosted in the same heroku container, proxied by React on localhost
        let fetch_result_url;

        if ( data_type_for_USA_MSH ) {

            fetch_result_url = "https://hammasoskari-proxy.herokuapp.com/https://expat-assurance.com/expat-plans-new/"+process.env.REACT_APP_LANGUAGE+"/"+insurance_company+"-"+company_guarantee_level+"-USA-"+data_type_for_USA_MSH+".json";

        } else if ( insurance_company === "Liberty") {

            if (destination_country === "Hong Kong" ||
                destination_country ===  "Chine" ||
                destination_country ===  "Japon" ||
                destination_country ===  "Australie" ||
                destination_country ===  "Nouvelle-Zélande" ||
                destination_country ===  "Corée du Sud" ||
                destination_country === "Taïwan" ||
                destination_country === "Malaisie") {

                fetch_result_url = "https://hammasoskari-proxy.herokuapp.com/https://expat-assurance.com/expat-plans-new/"+process.env.REACT_APP_LANGUAGE+"/hongkong/" + insurance_company + "-" + company_guarantee_level + ".json";

            } else if ( destination_country === "Thaïlande") {

                fetch_result_url ="https://hammasoskari-proxy.herokuapp.com/https://expat-assurance.com/expat-plans-new/"+process.env.REACT_APP_LANGUAGE+"/thailand/" + insurance_company + "-" + company_guarantee_level + ".json";

            } else if ( destination_country === "Singapour") {

                fetch_result_url ="https://hammasoskari-proxy.herokuapp.com/https://expat-assurance.com/expat-plans-new/"+process.env.REACT_APP_LANGUAGE+"/singapour/" + insurance_company + "-" + company_guarantee_level + ".json";
            }

        }  else if ( insurance_company === "PTI") {

            fetch_result_url = "https://hammasoskari-proxy.herokuapp.com/https://expat-assurance.com/expat-plans-new/"+process.env.REACT_APP_LANGUAGE+"/vietnam/" + insurance_company + "-" + company_guarantee_level + ".json";

        } else {

            fetch_result_url = "https://hammasoskari-proxy.herokuapp.com/https://expat-assurance.com/expat-plans-new/"+process.env.REACT_APP_LANGUAGE+"/"+insurance_company+"-"+company_guarantee_level+".json";

        }

        let fetch_result = fetch(fetch_result_url, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });


        fetch_result
            .then(response => {

                return response.json();

            })
            .then(json => {
                console.log(json);
                dispatch(saveInsurancePlanExtendedDataFromAPI(json));

            });

    }

}



// connection with API, and getting insurance plans extended data JSON for COMPARE PLANS

export function multipleFetchingInsurancePlansExtendedData(compare_plans_local_array, insurees_data, data_type_for_USA_MSH, destination_country) {

    return dispatch => {


        const multipleFetchData = async (compare_plans_local_array) => {

            let url = 'https://hammasoskari-proxy.herokuapp.com/https://expat-assurance.com/expat-plans-new/'+process.env.REACT_APP_LANGUAGE+"/";

            try {

                Promise.all(compare_plans_local_array.map((item_plan, i) => {

                    if ( item_plan.insurance_company === "MSH" && insurees_data.destination_country === "États-Unis d’Amérique" ) {

                        return fetch(url+item_plan.insurance_company+"-"+item_plan.company_guarantee_level+"-USA-"+data_type_for_USA_MSH+".json").then(resp => resp.json());

                    }  else if ( item_plan.insurance_company === "Liberty") {

                        if (destination_country === "Hong Kong" ||
                            destination_country ===  "Chine" ||
                            destination_country ===  "Japon" ||
                            destination_country ===  "Australie" ||
                            destination_country ===  "Nouvelle-Zélande" ||
                            destination_country ===  "Corée du Sud" ||
                            destination_country === "Taïwan" ||
                            destination_country === "Malaisie") {

                            return fetch("https://hammasoskari-proxy.herokuapp.com/https://expat-assurance.com/expat-plans-new/"+process.env.REACT_APP_LANGUAGE+"/hongkong/" + item_plan.insurance_company + "-" + item_plan.company_guarantee_level + ".json").then(resp => resp.json());

                        } else if ( destination_country === "Thaïlande") {

                            return fetch("https://hammasoskari-proxy.herokuapp.com/https://expat-assurance.com/expat-plans-new/"+process.env.REACT_APP_LANGUAGE+"/thailand/" + item_plan.insurance_company + "-" + item_plan.company_guarantee_level + ".json").then(resp => resp.json());
                        }
                        else if ( destination_country === "Singapour") {

                            return fetch("https://hammasoskari-proxy.herokuapp.com/https://expat-assurance.com/expat-plans-new/"+process.env.REACT_APP_LANGUAGE+"/singapour/" + item_plan.insurance_company + "-" + item_plan.company_guarantee_level + ".json").then(resp => resp.json());
                        }

                    }  else if ( item_plan.insurance_company === "PTI") {

                        return fetch("https://hammasoskari-proxy.herokuapp.com/https://expat-assurance.com/expat-plans-new/"+process.env.REACT_APP_LANGUAGE+"/vietnam/" + item_plan.insurance_company + "-" + item_plan.company_guarantee_level + ".json").then(resp => resp.json());

                    } else {

                        return fetch(url+item_plan.insurance_company+"-"+item_plan.company_guarantee_level+".json").then(resp => resp.json());

                    }

                })).then(json=> {

                    dispatch(saveInsurancePlansExtendedDataForComparePlansFromAPI(json));

                });

            } catch (error) {

                console.error(error);

            }

        }

        multipleFetchData(compare_plans_local_array);

    }

}



// connection with API, and sending selected insurance plan data JSON

export function sendSelectedInsurancePlanToEmail(data_for_sending) {

    return dispatch => {

        // this request is served by Express hosted in the same heroku container, proxied by React on localhost
        let fetch_result = fetch("https://expat-assurance.com/wp-json/quoteapi/getplan-submit/", {

            // let insurancePlansArray = fetch("/get-price-data/", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data_for_sending

            })
        });

        fetch_result
            .then(response => {

                return response.json();

            })
            .then(json => {

                if ( json === 'Email has sent successfully.' ) {

                    //    TODO: зробити перевірку

                } else {

                    // alert(json);

                }

            });

        //also submit a conversion to hubspot:
        // let fetch_hubspot_getplan_conversion = fetch("https://comparateur.expat-assurance.com/getplan-conversion-hubspot/", {
        //     method: "POST",
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         data_for_sending
        //
        //     })
        // });
        //
        // fetch_hubspot_getplan_conversion
        //     .then(response => {
        //         console.log('Hubspot was notified of submission');
        //     });
    }

}



// connection with API, and sending user CONTACT FORM data

export function sendUserContactFormDataToEmail(data_for_sending) {

    console.log(data_for_sending);

    return dispatch => {

        // this request is served by Express hosted in the same heroku container, proxied by React on localhost
        // let fetch_result = fetch("https://hammasoskari-proxy.herokuapp.com/http://tech.marffa.com/insurance/send-contact-form-data.php", {
        let fetch_result = fetch("https://expat-assurance.com/wp-json/quoteapi/contactform-submit/", {

            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data_for_sending
            })
        });

        fetch_result
            .then(response => {

                return response.json();

            })
            .then(json => {

                if ( json === 'Email has sent successfully.' ) {

                    //    TODO: зробити перевірку

                } else {

                    // alert(json);

                }

            });


        //this request is hosted in expressJs app alongside the app
        // let hubspot_contactus_conversion_fetch = fetch("/contact-us-conversion-hubspot/", {
        //     method: "POST",
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         data_for_sending
        //     })
        // });
        // hubspot_contactus_conversion_fetch
        //     .then(response => {
        //         return response.json();
        //     })
        //     .then(json => {
        //         if ( json === 'Email has sent successfully.' ) {
        //             //    TODO: зробити перевірку
        //         } else {
        //             // alert(json);
        //         }
        //     });

    }

}



// connection with API, and sending getting data JSON

export function sendGatingDataToEmail(data_for_sending) {

    // localStorage.setItem('gate_closed', 'false');

    return dispatch => {

        // this request is served by Express hosted in the same heroku container, proxied by React on localhost
        // let fetch_result = fetch("https://hammasoskari-proxy.herokuapp.com/https://comparateur.expat-assurance.com/submit-gate-hubspot/", {
        let fetch_result = fetch("/submit-gate-hubspot/", {

            // let insurancePlansArray = fetch("/get-price-data/", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data_for_sending
            })
        });

        fetch_result
            .then(response => {

                return response.json();

            })
            .then(json => {
                setTimeout(function() {
                    var _hsq = window._hsq = window._hsq || [];
                    _hsq.push(
                        ["identify",{
                            email: data_for_sending.user_gating_information.email
                        }
                        ]);
                    window._hsq.push(['trackPageView']);
                }, 5000);

                console.log("hubspot initialized");

                window.smartlook('track', 'GatePassedSmartlook');
                GAevent("Gate was passed", "test");
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                    event: "gate_was_passed",
                    gate_was_passed: true   // <--------
                })
                localStorage.setItem('gate_closed', false);
                dispatch(saveGatingResponseFromAPI(json));

            });

    }

}
