import i18next from "i18next";
import {initReactI18next, useTranslation} from 'react-i18next';

import EN_localization from "./localization/EN_localization.json"
import FR_localization from "./localization/FR_localization.json"
import ES_localization from "./localization/ES_localization.json"

const resources = {
    FR: {
        translation: FR_localization
    },
    EN: {
        translation: EN_localization
    },
    ES: {
        translation: ES_localization
    }
};

let app_environment_language = process.env.REACT_APP_LANGUAGE;
// console.log('environment language detected: ' + app_environment_language);

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: app_environment_language,
        keySeparator:false,
        interpolation: {
            escapeValue: false
        }
    }

)

export default i18next;
