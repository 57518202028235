import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './MainPage.css';
import waveBg from "../../../assets/images/wave-bg.svg";
import {Col, Container, Row, Modal} from "react-bootstrap";
import Select, { components } from 'react-select';
import {
    clearInsurancePlansArrayBeforeFetching,
    saveDataFromMainPageForm
} from "../../../actions/actions";
import { animateScroll as scroll } from 'react-scroll'
import {
    NATIONALITY_OPTIONS,
    AGE_OPTIONS,
    DESTINATION_COUNTRY_OPTIONS,
    INSURANCE_DURATION_OPTIONS
} from "../../../global-app-elements/global_selects_options";

// import expatLogo from "../../../assets/images/expat-logo.svg";
import {
    getAdultsAmount,
    textInputValidation
} from "../../../global-app-elements/global_helper_functions";
import Tooltip from "../../GlobalComponents/Tooltip/Tooltip";
import {Trans, useTranslation} from 'react-i18next';
import {modalViewGA, pageViewGA} from "../../../global-app-elements/ga-functions";

import i18n from 'i18next';

const howItWork = require('./../../../assets/images/'+i18n.t('mainPage_how_it_work'));
// let howItWork = require('../../../assets/images/'+i18n.t('mainPage_how_it_work')+'how-it-work.png');

console.log(howItWork)





const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="icon-arrow-down"></span>
        </components.DropdownIndicator>
    );
};







class MainPage extends Component {


    constructor(props) {

        super(props);

        let insurees_data;

        if ( this.props.insurees_data ) {

            insurees_data = this.props.insurees_data;

        } else {

            insurees_data = {
                insuree_object: {
                    nationality: 'France',
                    age: null
                },
                dependents_array: [],
                destination_country: null,
                insurance_duration: '12>',
                insuree_has_cfe: 'NOT_KNOWN'
            };

        }

        this.state = {
            insurees_data: insurees_data,
            form_validation_error_detected: false,
            is_modal_visible: false,
            modal_content_type: null
        };

        this.handleChangeNationality = this.handleChangeNationality.bind(this);
        this.handleChangeAge = this.handleChangeAge.bind(this);
        this.handleChangeDependentsNationality = this.handleChangeDependentsNationality.bind(this);
        this.handleChangeDependentsAge = this.handleChangeDependentsAge.bind(this);
        this.handleChangeDestinationCountry = this.handleChangeDestinationCountry.bind(this);
        this.handleChangeInsuranceDuration = this.handleChangeInsuranceDuration.bind(this);
        this.handleChangeInsureeHasCfe = this.handleChangeInsureeHasCfe.bind(this);
        this.handleGetAQuote = this.handleGetAQuote.bind(this);
        this.handleDeleteDependentItem = this.handleDeleteDependentItem.bind(this);
        this.handleAddDependentItem = this.handleAddDependentItem.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);

    }


    componentDidMount() {

        // TRIGGER PAGE VIEW FOR GOOGLE ANALYTICS
        pageViewGA('/');

    }


    handleChangeNationality(selected_option) {

        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                insuree_object: {
                    ...prevState.insurees_data.insuree_object,
                    nationality: selected_option.value
                }
            }
        }));

    };


    handleChangeAge(selected_option) {

        // перевірка чи не буде новий вік перевищувати допустипу кількість страших людей,
        // які вже добавлені у форму
        let adults_amount = getAdultsAmount(this.state.insurees_data, 'complex_array');
        let current_insuree_age = this.state.insurees_data.insuree_object.age;
        let selected_value;

        if ( selected_option.value > 26 ) {

            adults_amount++;

            if ( adults_amount > 2 && ( current_insuree_age === null || current_insuree_age <= 26 )) {

                this.handleShowModal('warning-message-adults-amount');
                selected_value = null;

            } else {

                selected_value = selected_option.value;

            }

        } else {

            selected_value = selected_option.value;

        }


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                insuree_object: {
                    ...prevState.insurees_data.insuree_object,
                    age: selected_value
                }
            }
        }));

    };


    handleChangeDependentsNationality(position_in_list , selected_option) {

        let dependents_array = [...this.state.insurees_data.dependents_array];

        dependents_array[position_in_list].nationality = selected_option.value;


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                dependents_array: dependents_array
            }
        }));

    };


    handleChangeDependentsAge(position_in_list, selected_option) {

        // перевірка чи не буде новий вік перевищувати допустипу кількість страших людей,
        // які вже добавлені у форму
        let adults_amount = getAdultsAmount(this.state.insurees_data, 'complex_array');
        let dependents_array = [...this.state.insurees_data.dependents_array];
        let current_dependent_age = dependents_array[position_in_list].age;

        if ( selected_option.value > 26 ) {

            adults_amount++;

            if ( adults_amount > 2 && ( current_dependent_age === null || current_dependent_age <= 26 )) {

                this.handleShowModal('warning-message-adults-amount');
                dependents_array[position_in_list].age = null;

            } else {

                dependents_array[position_in_list].age = selected_option.value;

            }

        } else {

            dependents_array[position_in_list].age = selected_option.value;

        }


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                dependents_array: dependents_array
            }
        }));

    };


    handleChangeDestinationCountry(selected_option) {

        let main_insuree_nationality = this.state.insurees_data.insuree_object.nationality;
        let destination_country;

        if ( selected_option.value === main_insuree_nationality ) {

            this.handleShowModal('warning-message-expatriation-country');

            destination_country = null;

        } else {

            destination_country = selected_option.value;

        }

        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                destination_country: destination_country
            }
        }));

    };


    handleChangeInsuranceDuration(selected_option) {

        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                insurance_duration: selected_option.value
            }
        }));

    };


    handleChangeInsureeHasCfe(selected_option) {

        let input_value = selected_option.target.value;

        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                insuree_has_cfe: input_value
            }
        }));

    };


    handleAddDependentItem() {

        let dependents_array = [...this.state.insurees_data.dependents_array];

        dependents_array.push({
            nationality: this.state.insurees_data.insuree_object.nationality,
            age: null
        });


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                dependents_array: dependents_array
            }
        }));


    }


    handleDeleteDependentItem(position_in_list) {


        let dependents_array = [...this.state.insurees_data.dependents_array];

        dependents_array.splice(position_in_list, 1);


        this.setState(prevState => ({
            insurees_data: {
                ...prevState.insurees_data,
                dependents_array: dependents_array
            }
        }));

    }


    handleGetAQuote() {

        // TODO: Зробити толкову валідацію

        let not_valid_inputs_array = document.querySelectorAll('.mainPageFormContainer .not-valid-input');

        if ( not_valid_inputs_array.length > 0 ) {

            this.setState({ form_validation_error_detected: true });

            not_valid_inputs_array[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});

        } else {

            this.props.onClearInsurancePlansArrayBeforeFetching();
            this.props.onSaveDataFromMainPageForm( this.state.insurees_data );
            this.props.history.push('/insurance');

        }

    }


    handleShowModal(content_type) {

        this.setState({
            is_modal_visible: true,
            modal_content_type: content_type
        });

        // TRIGGER MODAL VIEW FOR GOOGLE ANALYTICS
        modalViewGA(content_type);

    }


    handleCloseModal() {

        this.setState({
            is_modal_visible: false,
            modal_content_type: null
        });

    }


    renderDependentsList() {

        let dependents_array = this.state.insurees_data.dependents_array;
        let insurees_data = this.state.insurees_data;


        if ( dependents_array.length > 0 ) {

            return this.state.insurees_data.dependents_array.map((item, index) => {

                return (

                    <Row key={index} className="insuranceRow dependentsItemRow">
                        <Col xs={7}>
                            <div className={`inputBox ${(insurees_data.dependents_array[index].nationality) ? "is-valid-input" : "not-valid-input" }`}>
                                <Select
                                    value={ NATIONALITY_OPTIONS.filter(option => option.value === insurees_data.dependents_array[index].nationality) }
                                    defaultValue={0}
                                    components={{ DropdownIndicator }}
                                    positionInList={0}
                                    classNamePrefix="insurance-main-select"
                                    className="select_nationality"
                                    onChange={this.handleChangeDependentsNationality.bind(this, index)}
                                    options={NATIONALITY_OPTIONS}
                                />
                            </div>
                        </Col>

                        <Col xs={3}>
                            <div className={`inputBox ${(insurees_data.dependents_array[index].age) ? "is-valid-input" : "not-valid-input" }`}>
                                <Select
                                    value={ AGE_OPTIONS.filter(option => option.value === insurees_data.dependents_array[index].age) }
                                    placeholder="0"
                                    components={{ DropdownIndicator }}
                                    classNamePrefix="insurance-main-select"
                                    onChange={this.handleChangeDependentsAge.bind(this, index)}
                                    options={AGE_OPTIONS}
                                />
                            </div>
                        </Col>

                        <Col xs={2}>
                            <button className="deleteDependentButton" onClick={this.handleDeleteDependentItem.bind(this, index)}>
                                <span className="icon-trash"></span>
                            </button>
                        </Col>
                    </Row>

                );

            });

        }

    }


    renderModalContent() {

        switch ( this.state.modal_content_type ) {
            case 'warning-message-expatriation-country':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        centered={true}
                        dialogClassName="warning-centered-modal">
                        <Modal.Header closeButton>
                            <Container className="defaultContainer">
                                {/*<button className="close-modal-button" variant="secondary" onClick={this.handleCloseModal}></button>*/}
                                <Modal.Title>
                                    <span className="icon-question"></span>
                                    <Trans i18nKey={"global_warning_message_expatriation_country_modal_title"}/>
                                </Modal.Title>
                            </Container>
                        </Modal.Header>
                        <Modal.Body>
                            <div> <Trans i18nKey={"global_warning_message_expatriation_country_modal_subtitle"}/></div>
                            <button className="secondaryButtonStyle" onClick={this.handleCloseModal}><Trans i18nKey={"getPlanePage_criteries"}/></button>
                        </Modal.Body>
                    </Modal>

                );

            case 'warning-message-adults-amount':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        centered={true}
                        dialogClassName="warning-centered-modal">
                        <Modal.Header closeButton>
                            <Container className={`defaultContainer`+" "+ process.env.REACT_APP_LANGUAGE+"_reset_style_class"}>
                                {/*<button className="close-modal-button" variant="secondary" onClick={this.handleCloseModal}></button>*/}
                                <Modal.Title>
                                    <span className="icon-question"></span>
                                    <Trans i18nKey={"global_warning_message_adults_amount_modal_title"} />
                                </Modal.Title>
                            </Container>
                        </Modal.Header>
                        <Modal.Body>
                            {/*<div>Vous pouvez modifier votre choix pour accéder aux résultats.</div>*/}
                            <button className="secondaryButtonStyle" onClick={this.handleCloseModal}><Trans i18nKey={"getPlanePage_criteries"}/></button>
                        </Modal.Body>
                    </Modal>

                );

            default:

                return null

        }

    }


    scrollToTop() {

        scroll.scrollToTop();

    }



    render() {

        let insurees_data = this.state.insurees_data;

        return (


            <div className="mainPage">

                <Container className="smallContainer mainPageContainer">

                    <Row>
                        <Col xs={{ span: 12, order: 'last' }} lg={{ span: 6, order: 'first' }}>

                            <div className="mainPageTitle only-desktop">

                                <h1><Trans i18nKey="mainPage_left_side_header_text" components={{i : <span/>}}></Trans></h1>

                            </div>
                            <div className="mainPageDescription only-desktop">

                                <h2>
                                    <Trans i18nKey="mainPage_left_side_header_sub_text"></Trans>
                                </h2>

                            </div>

                            <div className="mainPageHowItWork">
                                <img src={howItWork} alt="Expat Assurance" />
                            </div>

                            <Row>
                                <Col xs={12} className="mainPageInfoBoxContainer">
                                    <Row className="mainPageInfoBox">
                                        <Col xs={3}>
                                            <span className="icon-cup"></span>
                                        </Col>
                                        <Col xs={9}>
                                            <Trans i18nKey="mainPage_left_side_header_buttom_text" components={{i : <span/>}}></Trans>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <button
                                        onClick={this.scrollToTop}
                                        className="scrollToFormButton secondaryButtonStyle only-mobile">
                                        <Trans i18nKey="mainPage_form_submit_button"></Trans></button>
                                </Col>
                            </Row>

                        </Col>
                        <Col className="testingVersionForMainForm" xs={{ span: 12, order: 'first' }} lg={{ span: 6, order: 'last' }}>


                            <div className="mainPageMobileHead only-mobile">
                                <div className="mainPageTitle">
                                    <h1><Trans i18nKey="mainPage_left_side_header_text" components={{i : <span/>}}></Trans></h1>
                                </div>
                                {/*<div className="mainPageDescription">*/}

                                {/*    <h2>*/}
                                {/*        Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                                {/*        sed do eiusmod tempor incididunt.*/}
                                {/*    </h2>*/}

                                {/*</div>*/}
                            </div>

                            <div className={`mainPageFormContainer ${(this.state.form_validation_error_detected) ? "form-validation-error" : null }`}>

                                <div className="mainPageFormTitle"><Trans i18nKey="mainPage_form_title"></Trans></div>

                                <Row className="insuranceRow">

                                    <Col xs={7}>
                                        <div className={`inputBox ${(textInputValidation(insurees_data.insuree_object.nationality)) ? "is-valid-input" : "not-valid-input" }`}>
                                            <label><Trans i18nKey="mainPage_form_nationality"></Trans><span className="red">*</span></label>
                                            <Select
                                                value={ NATIONALITY_OPTIONS.filter(option => option.value === insurees_data.insuree_object.nationality) }
                                                components={{ DropdownIndicator }}
                                                positionInList={0}
                                                placeholder={i18n.t("global_select")}
                                                classNamePrefix="insurance-main-select"
                                                className="select_nationality"
                                                onChange={this.handleChangeNationality}
                                                options={NATIONALITY_OPTIONS}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs={3}>
                                        <div className={`inputBox ${(insurees_data.insuree_object.age) ? "is-valid-input" : "not-valid-input" }`}>
                                            <label><Trans i18nKey="mainPage_form_age"></Trans><span className="red">*</span></label>
                                            <Select
                                                value={ AGE_OPTIONS.filter(option => option.value === insurees_data.insuree_object.age) }
                                                placeholder="0"
                                                components={{ DropdownIndicator }}
                                                classNamePrefix="insurance-main-select"
                                                onChange={this.handleChangeAge}
                                                options={AGE_OPTIONS}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs={2}>
                                        <button className="addDependentsButton" onClick={this.handleAddDependentItem}>
                                            <span className="icon-user"></span> <Trans i18nKey="mainPage_form_add_person"></Trans>
                                        </button>
                                    </Col>

                                </Row>

                                <div className="dependentsContainer">

                                    { this.renderDependentsList() }

                                </div>

                                <Row>

                                    <Col xs={12}>
                                        <div className={`inputBox ${(insurees_data.destination_country) ? "is-valid-input" : "not-valid-input" }`}>
                                            <label><Trans i18nKey="mainPage_form_country_emigration"></Trans><span className="red">*</span></label>
                                            <Select
                                                value={ DESTINATION_COUNTRY_OPTIONS.filter(option => option.value === insurees_data.destination_country) }
                                                components={{ DropdownIndicator }}
                                                placeholder={i18n.t("mainPage_select_the_country")}
                                                classNamePrefix="insurance-main-select"
                                                onChange={this.handleChangeDestinationCountry}
                                                options={DESTINATION_COUNTRY_OPTIONS.sort((prev, next) => {
                                                    if ( prev.label < next.label ) return -1;
                                                    if ( prev.label < next.label ) return 1;
                                                })}
                                            />
                                        </div>
                                    </Col>

                                </Row>

                                <Row className="noDisplay">

                                    <Col xs={12}>
                                        <div className={`inputBox ${(insurees_data.insurance_duration) ? "is-valid-input" : "not-valid-input" }`}>
                                            <label><Trans i18nKey="mainPage_form_time"></Trans></label>
                                            <Select
                                                value={ INSURANCE_DURATION_OPTIONS.filter(option => option.value === insurees_data.insurance_duration) }
                                                components={{ DropdownIndicator }}
                                                classNamePrefix="insurance-main-select"
                                                onChange={this.handleChangeInsuranceDuration}
                                                options={INSURANCE_DURATION_OPTIONS}
                                                isSearchable={false}
                                            />
                                        </div>
                                    </Col>

                                </Row>

                                {(process.env.REACT_APP_LANGUAGE === "FR") ? <Row>

                                    <Col xs={12}>
                                        <div className="inputBox radioInputBox">
                                            <label>
                                                <Trans i18nKey="mainPage_form_cfe_coverege"></Trans>
                                                <Tooltip
                                                    tooltipId="main_cfe"
                                                    tooltipType="light"
                                                    tooltipEffect="solid"
                                                    tooltipIconSize={16}
                                                    tooltipIconTopPosition={2}
                                                    tooltipIconOffset={5}
                                                    tooltipContent={i18n.t("mainPage_cfe_coverege_i")}
                                                />
                                            </label>

                                            <Row className="radioBlockRow">

                                                <Col xs={3}>
                                                    <input
                                                        type="radio"
                                                        id="insuree_has_cfe_yes"
                                                        value="YES"
                                                        name="cfe_radio"
                                                        defaultChecked={(insurees_data.insuree_has_cfe === "YES") ? true : false}
                                                        onChange={this.handleChangeInsureeHasCfe}
                                                    />
                                                    <label htmlFor="insuree_has_cfe_yes"><Trans i18nKey="mainPage_form_cfe_yes"></Trans></label>
                                                </Col>
                                                <Col xs={3} className="mediumRadioCol">
                                                    <input
                                                        type="radio"
                                                        id="insuree_has_cfe_no"
                                                        value="NO"
                                                        name="cfe_radio"
                                                        defaultChecked={(insurees_data.insuree_has_cfe === "NO") ? true : false}
                                                        onChange={this.handleChangeInsureeHasCfe}
                                                    />
                                                    <label htmlFor="insuree_has_cfe_no"><Trans i18nKey="mainPage_form_cfe_no"></Trans></label>
                                                </Col>
                                                <Col xs={6}>
                                                    <input
                                                        type="radio"
                                                        id="insuree_has_cfe_not_know"
                                                        value="NOT_KNOWN"
                                                        name="cfe_radio"
                                                        defaultChecked={(insurees_data.insuree_has_cfe === "NOT_KNOWN") ? true : false}
                                                        onChange={this.handleChangeInsureeHasCfe}
                                                    />
                                                    <label htmlFor="insuree_has_cfe_not_know"><Trans i18nKey="mainPage_form_cfe_dont_know"></Trans></label>
                                                </Col>

                                            </Row>

                                        </div>
                                    </Col>

                                </Row> : null }

                                <Row>
                                    <Col xs={12} className="legalCol">
                                        <button className="mainButtonStyle handleGetAQuoteButton" onClick={this.handleGetAQuote}>
                                            <Trans i18nKey="mainPage_form_submit_button"></Trans> <span className="icon-big-arrow-right"></span>
                                        </button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className="legalCol">
                                        <p>
                                            <Trans i18nKey="legal_text"/> <a href={i18n.t("legal_link")} target="_blank"> <Trans i18nKey="legal_link_text"/></a>.
                                        </p>
                                    </Col>
                                </Row>

                            </div>

                        </Col>
                    </Row>

                    {/* BACKGROUND WAVES */}
                    <img className="waveBg1" src={waveBg} alt="Expat Assurance" />
                    <img className="waveBg2" src={waveBg} alt="Expat Assurance" />

                </Container>


                {/*  MODALS PART  */}
                { this.renderModalContent() }

            </div>

        )

    }


}


export default withRouter(connect(
    state => ({
        insurees_data: state.main_form_data.insurees_data
    }),
    dispatch => ({
        onSaveDataFromMainPageForm: (main_form_data_object) => {
            dispatch(saveDataFromMainPageForm(main_form_data_object));
        },
        onClearInsurancePlansArrayBeforeFetching: () => {
            dispatch(clearInsurancePlansArrayBeforeFetching());
        }
    })
)(MainPage));

