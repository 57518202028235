import React, { Component } from 'react';
import './GetPlanPage.css';
import {Col, Container, Modal, Row} from "react-bootstrap";
import Select, { components } from 'react-select';
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import {fr, es} from 'date-fns/esm/locale';
import i18n from 'i18next';


import instructionIcon  from '../../../assets/images/get-plan-instruction.svg';
import {
    calculateFinalPrice,
    renderGuaranteeLevelNameTag,
    renderInsuranceCompanyLogo,
    renderTypeOfCoverageList,
    generateFormattedPrice,
    textInputValidation,
    emailInputValidation,
    getAdultsAmount
} from "../../../global-app-elements/global_helper_functions";
import {
    AGE_OPTIONS,
    INSURANCE_DURATION_OPTIONS,
    PAYMENT_FREQUENCY_OPTIONS,
} from "../../../global-app-elements/global_selects_options";
import {
    changePaymentFrequencyValue, fetchInsurancePlanExtendedData,
    saveInsurancePlanDataForViewPlan,
    sendSelectedInsurancePlanToEmail
} from "../../../actions/actions";
import ViewPlan from "../../ModalsBody/ViewPlan/ViewPlan";
import Tooltip from "../../GlobalComponents/Tooltip/Tooltip";
import {GAevent, modalViewGA, pageViewGA} from "../../../global-app-elements/ga-functions";


import {Trans} from 'react-i18next';
import i18next from "i18next";


const cloneDeep = require('clone-deep');
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="icon-arrow-down"></span>
        </components.DropdownIndicator>
    );
};

const Checkbox = (props) => (
    <input type="checkbox" {...props} inputmode='numeric'/>
);


let moment = require('moment');





class GetPlanPage extends Component {


    constructor(props) {

        window.scrollTo(0, 0);

        super(props);

        let insurees_personal_information_array = [];

        // Якщо користувач потрапляє на цю сторінук без даних форми з першої main page АЛЕ БЕЗ даних про обраний план,
        // юзера редіректить на першу сторінку автоматично
        if ( !this.props.get_plan_data && this.props.insurees_data ) {

            this.props.history.push('/insurance');

        }

        // Якщо користувач потрапляє на цю сторінук без даних форми з першої main page,
        // юзера редіректить на першу сторінку автоматично
        if( !this.props.insurees_data ) {

            this.props.history.push('/');

        } else {

            // Генеруємо список "personal information of people included to insurance plan"
            // на основі insurees_data з попередніх steps
            let insuree_object = this.props.insurees_data.insuree_object;
            let dependents_array = this.props.insurees_data.dependents_array;

            insurees_personal_information_array.push({
                full_name: null,
                nationality: insuree_object.nationality,
                age: insuree_object.age
            });

            if ( dependents_array.length > 0 ) {

                dependents_array.forEach(dependent => {
                    insurees_personal_information_array.push({
                        full_name: null,
                        nationality: dependent.nationality,
                        age: dependent.age
                    });
                });

            }

        }


        this.state = {
            user_personal_information: {
                insurees_personal_information_array: insurees_personal_information_array,
                contact_information_object: {
                    full_name: null,
                    email: null,
                    phone: null,
                    message: null
                },
                date_of_subscription: null,
            },
            form_validation_error_detected: false,
            is_modal_visible: false,
            modal_content_type: null,
            validation: false
        };


        this.handleChangeInsureesPersonalInformationAge = this.handleChangeInsureesPersonalInformationAge.bind(this);
        this.handleChangeInsureesPersonalInformationFullName = this.handleChangeInsureesPersonalInformationFullName.bind(this);
        this.handleChangeDateOfSubscription = this.handleChangeDateOfSubscription.bind(this);
        this.handleChangeContactInformationFullName = this.handleChangeContactInformationFullName.bind(this);
        this.handleChangeContactInformationEmail = this.handleChangeContactInformationEmail.bind(this);
        this.handleChangeContactInformationPhone = this.handleChangeContactInformationPhone.bind(this);
        this.handleChangeContactInformationMessage = this.handleChangeContactInformationMessage.bind(this);
        this.handleChangePaymentFrequencyOption = this.handleChangePaymentFrequencyOption.bind(this);
        this.handleReceiveACall = this.handleReceiveACall.bind(this);
        this.handleViewPlan = this.handleViewPlan.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    }


    componentDidMount() {

        // TRIGGER PAGE VIEW FOR GOOGLE ANALYTICS
        pageViewGA('get-plan');

        //TODO: Винести функцію і забрати костиль
                if (this.props.get_plan_data) {
                    if (this.props.get_plan_data.insurance_company === "MSH" && this.props.insurees_data.destination_country === "États-Unis d’Amérique") {

                        this.props.onFetchInsurancePlanExtendedData(
                            this.props.get_plan_data.insurance_company,
                            this.props.get_plan_data.company_guarantee_level,
                            'DANS'
                        );

                    } else {

                        this.props.onFetchInsurancePlanExtendedData(
                            this.props.get_plan_data.insurance_company,
                            this.props.get_plan_data.company_guarantee_level,
                            null
                        );

                    }
                }
    }


    componentDidUpdate(prevProps, prevState) {

        // Якщо користувач клікнув на Gat Plan , таким чином він оновив selected plan in global Redux Store
        // цей рядок коду слухає цю зміну, і кидає користувача на сторінку Gat Plan Page , якщо це сталося
        if (prevProps.get_plan_data !== this.props.get_plan_data) {

            this.handleCloseModal();

        }

        // Якщо користувач клікнув на Gat Plan , таким чином він оновив selected plan in global Redux Store
        // цей рядок коду слухає цю зміну, і кидає користувача на сторінку Gat Plan Page , якщо це сталося
        if (prevProps.view_plan_data !== this.props.view_plan_data) {

            this.handleShowModal('view-plan');

        }

    }


    handleChangeInsureesPersonalInformationAge(position_in_list, selected_option) {


        // перевірка чи не буде новий вік перевищувати допустипу кількість страших людей,
        // які вже добавлені у форму
        let insurees_array = [...this.state.user_personal_information.insurees_personal_information_array];
        let adults_amount = getAdultsAmount(insurees_array, 'solid_array');
        let current_insuree_age = insurees_array[position_in_list].age;


        if ( selected_option.value > 26 ) {

            adults_amount++;

            if ( adults_amount > 2 && ( current_insuree_age === null || current_insuree_age <= 26 )) {

                this.handleShowModal('warning-message-adults-amount');
                insurees_array[position_in_list].age = null;

            } else {

                insurees_array[position_in_list].age = selected_option.value;

            }

        } else {

            insurees_array[position_in_list].age = selected_option.value;

        }


        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                insurees_personal_information_array: insurees_array
            }
        }));

    };


    handleChangeInsureesPersonalInformationFullName(position_in_list, selected_option) {

        let insurees_array = [...this.state.user_personal_information.insurees_personal_information_array];

        insurees_array[position_in_list].full_name = selected_option.target.value;


        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                insurees_personal_information_array: insurees_array
            }
        }));

    };


    handleChangeDateOfSubscription(changed_value) {

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                date_of_subscription: changed_value
            }
        }));

    };


    handleChangeContactInformationFullName(changed_value) {

        let input_value = changed_value.target.value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    full_name: input_value
                }
            }
        }));

    };


    handleChangeContactInformationEmail(changed_value) {

        let input_value = changed_value.target.value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    email: input_value
                }
            }
        }));

    };


    handleChangeContactInformationPhone(changed_value) {

        let input_value = changed_value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    phone: input_value
                }
            }
        }));

    };


    handleChangeContactInformationMessage(changed_value) {

        let input_value = changed_value.target.value;

        this.setState(prevState => ({
            user_personal_information: {
                ...prevState.user_personal_information,
                contact_information_object: {
                    ...prevState.user_personal_information.contact_information_object,
                    message: input_value
                }
            }
        }));

    };


    handleChangePaymentFrequencyOption(selected_option)  {

        this.props.onChangePaymentFrequencyValue(selected_option.value)

    }


    handleViewPlan(plan_id) {

        let result_insurance_plans_array = cloneDeep(this.props.result_insurance_plans_array);

        if ( result_insurance_plans_array ) {

            let current_plan_array = result_insurance_plans_array.filter(option => option.plan_id === plan_id);

            this.props.onSaveInsurancePlanDataForViewPlan(current_plan_array[0]);

        }

    }

    handleChangeCheckbox() {

        let isChecked = document.querySelector('.checkboxColDiv input').checked;
        console.log(isChecked)
        if(isChecked === true){
            document.getElementsByClassName("checkboxCol")[0].classList.add('is-valid-input');
            document.getElementsByClassName("checkboxCol")[0].classList.remove('not-valid-input');
        } else {
            document.getElementsByClassName("checkboxCol")[0].classList.add('not-valid-input');
            document.getElementsByClassName("checkboxCol")[0].classList.remove('is-valid-input');
        }

        console.log(isChecked)

        this.setState(prevState => ({
            ...prevState,
            validation: isChecked
        }));
    }


    handleReceiveACall() {

        // TODO: Зробити толкову валідацію
        let insurees_data = this.props.insurees_data;
        let not_valid_inputs_array = document.querySelectorAll('.getPlanPageRightPart .not-valid-input');

        this.handleChangeCheckbox();

        if ( not_valid_inputs_array.length > 0 ) {

            this.setState({ form_validation_error_detected: true });

            not_valid_inputs_array[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});

        } else {

            let selected_plan = this.props.get_plan_data;
            let data_for_sending = {
                user_language: process.env.REACT_APP_LANGUAGE,
                user_personal_information: this.state.user_personal_information,
                coverage_sum: this.props.server_plan_data.coverage_sum,
                insurees_data: insurees_data,
                payment_frequency_value: (PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value))[0].label,
                get_plan_data: this.props.get_plan_data,
                insurance_plan_price: generateFormattedPrice(selected_plan.currentPrice, this.props.currency)
            }

            let money_saving_selected = "No money saving selected";
            if (data_for_sending.get_plan_data.coinsurance_selected_object != null) {
                money_saving_selected = "Coinsurance selected: " + data_for_sending.get_plan_data.coinsurance_selected_object.label
            };
            if (data_for_sending.get_plan_data.deductible_selected_object != null) {
                money_saving_selected = "Deductible selected: " + data_for_sending.get_plan_data.deductible_selected_object.label
            };

            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', window.location.href]);
            _hsq.push(["identify",{
                firstname: 'converted in app',
                lastname: 'get-plan '  + data_for_sending.user_personal_information.contact_information_object.full_name,
                email: data_for_sending.user_personal_information.contact_information_object.email,
                phone: data_for_sending.user_personal_information.contact_information_object.phone,
                commentaires: "Get-plan conversion happened in app on " + new Date().toUTCString() + " \n\n " + data_for_sending.user_personal_information.contact_information_object.message ,
                app_calculated_insurance_price: data_for_sending.insurance_plan_price,
                in_app_plan_id: data_for_sending.get_plan_data.plan_id,
                guarantee_level_in_companys_name: data_for_sending.get_plan_data.company_guarantee_level,
                payment_frequency_selected_in_app: data_for_sending.payment_frequency_value,
                money_saving_option_selected: money_saving_selected,
                quelle_couverture_cherche_t_il_: data_for_sending.get_plan_data.coverage_types_included ,
                dur_e_du_contrat_souhait_: data_for_sending.insurees_data.insurance_duration,
                adh_rent_cfe: data_for_sending.insurees_data.insuree_has_cfe,
                leadin_pays_de_destination_bc1e211f71cc3f2e8346f8719273fb80: data_for_sending.insurees_data.destination_country,
                leadin_pays_de_nationalitswb_0b819286eb1d5bab2234c31836f1e050: data_for_sending.insurees_data.insuree_object.nationality,
                user_age_from_search: data_for_sending.insurees_data.insuree_object.age,
                dependents_array: JSON.stringify(data_for_sending.insurees_data.dependents_array)
            }]);
            _hsq.push(['trackPageView']);

            this.props.onSendSelectedInsurancePlanToEmail(data_for_sending);

            GAevent('comparateur get-plan filled', 'comparateur');
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: "comparateur_get_plan_filled",
                comparateur_get_plan_filled: true   // <--------
            })

            this.props.history.push('/thank-you');

        }


    }


    handleShowModal(content_type) {

        this.setState({
            is_modal_visible: true,
            modal_content_type: content_type
        });

        // TRIGGER MODAL VIEW FOR GOOGLE ANALYTICS
        modalViewGA(content_type);

    }


    handleCloseModal() {

        this.setState({ is_modal_visible: false });

    }



    renderInsureesPersonalInformationList() {

        let insurees_array = this.state.user_personal_information.insurees_personal_information_array;

        if (insurees_array.length > 0) {

            return insurees_array.map((item, index) => {

                return (

                    <Row key={index}>

                        <Col xs={8}>
                            <div className={`inputBox ${(textInputValidation(item.full_name)) ? "is-valid-input" : "not-valid-input" }`}>
                                <label><Trans i18nKey="getPlanePage_your_contacts_name"/></label>
                                <input
                                    type="text"
                                    placeholder={i18n.t("global_your_name_placeholder")}
                                    defaultValue={item.full_name}
                                    onChange={this.handleChangeInsureesPersonalInformationFullName.bind(this, index)}
                                />
                            </div>
                        </Col>

                        <Col xs={4}>
                            <div className={`inputBox ${(item.age) ? "is-valid-input" : "not-valid-input" }`}>
                                <label><Trans i18nKey="getPlanePage_your_contacts_age"/></label>
                                <Select
                                    value={ AGE_OPTIONS.filter(option => option.value === item.age) }
                                    placeholder="0"
                                    components={{ DropdownIndicator }}
                                    classNamePrefix="insurance-main-select"
                                    onChange={this.handleChangeInsureesPersonalInformationAge.bind(this, index)}
                                    options={AGE_OPTIONS}
                                />
                            </div>
                        </Col>

                    </Row>

                );

            });

        }

    }



    renderModalContent() {

        switch ( this.state.modal_content_type ) {
            case 'view-plan':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="full-window-modal">
                        <Modal.Header closeButton>
                            <Container className={`defaultContainer`+" "+ process.env.REACT_APP_LANGUAGE+"_reset_style_class"}>
                                <button className="close-modal-button" variant="secondary" onClick={this.handleCloseModal}>
                                    <span className="icon-big-arrow-left"></span>
                                    <span className="close-modal-button-title"><Trans i18nKey="getPlanePage_back_arrow"></Trans></span>
                                </button>
                                <Modal.Title><Trans i18nKey="getPlanePage_plane_details_title"></Trans></Modal.Title>
                            </Container>
                        </Modal.Header>
                        <Modal.Body>
                            <ViewPlan />
                        </Modal.Body>
                    </Modal>

                );

            case 'warning-message-adults-amount':
                return (

                    <Modal
                        show={this.state.is_modal_visible}
                        onHide={this.handleCloseModal}
                        backdrop="static"
                        keyboard={false}
                        centered={true}
                        dialogClassName="warning-centered-modal">
                        <Modal.Header closeButton>
                            <Container className="defaultContainer">
                                {/*<button className="close-modal-button" variant="secondary" onClick={this.handleCloseModal}></button>*/}
                                <Modal.Title>
                                    <span className="icon-question"></span>
                                    <Trans i18nKey={"getPlanePage_modal_text"}/>
                                </Modal.Title>
                            </Container>
                        </Modal.Header>
                        <Modal.Body>
                            {/*<div>Vous pouvez modifier votre choix pour accéder aux résultats.</div>*/}
                            <button className="secondaryButtonStyle" onClick={this.handleCloseModal}><Trans i18nKey={"getPlanePage_criteries"}/></button>
                        </Modal.Body>
                    </Modal>

                );

            default:

                return null

        }

    }



    render() {


        let selected_plan = this.props.get_plan_data;
        let insurees_data = this.props.insurees_data;
        let contact_information_object = this.state.user_personal_information.contact_information_object;

        if ( selected_plan ) {

            return (

                <div className="getPlanPage">

                    {/* Фіксована кнопка фільтрів, тільки для мобільної версії */}
                    <Link to={"/insurance"}>
                        <button className="mobileLinkHeaderButton only-mobile">
                            <span className="icon-big-arrow-left"></span><Trans i18nKey="getPlanePage_back_arrow"></Trans>
                        </button>
                    </Link>


                    <div className="getPlanInstructionBlock">
                        <div className="getPlanInstructionIcons">
                            <img src={instructionIcon} alt="Expat Assurance" />
                        </div>
                        <div className="getPlanInstructionText">
                            <Trans i18nKey="getPlanePage_instruction_text" components={{i : <span/>}}/>
                        </div>
                    </div>

                    <Container className="defaultContainer getPlanPageContainer">

                        <Row>
                            <Col className="getPlanPageLeftPart" xs={4}>

                                <Link to="/insurance">
                                    <button className="goToResultPage"><span className="icon-big-arrow-left"></span> <Trans i18nKey="getPlanePage_back_to_plans"></Trans></button>
                                </Link>

                                <div className="summaryCard insurancePlanCard">
                                    <div className="summaryCardTitle"><Trans i18nKey="getPlanePage_summary_card_title"></Trans></div>
                                    <div className="summaryCardBody">

                                        <Row>
                                            <Col xs={12}>
                                                <div className="summaryCardGuaranteeLevel">
                                                    { renderGuaranteeLevelNameTag(selected_plan.guarantee_level_EA_displayed) }
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <div className="summaryCardLogo">
                                                    <img src={renderInsuranceCompanyLogo(selected_plan.insurance_company)} alt={selected_plan.insurance_company} />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <div className="summaryCardViewButton">
                                                    <button
                                                        className="secondaryButtonStyle viewPlanButton"
                                                        onClick={this.handleViewPlan.bind(this, selected_plan.plan_id)}><Trans i18nKey="getPlanePage_plane_details_title"/></button>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <div className="summaryCardTypeOfCoverageList">
                                                    { renderTypeOfCoverageList(selected_plan.coverage_types_included, selected_plan.insurance_company, selected_plan.plan_id) }
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <div className="paymentFrequencyBlock">
                                                    <label><Trans i18nKey="coveregePage_payment_type_title"/></label>
                                                    <Select
                                                        value={ (this.props.payment_frequency_value) ? PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value) : null }
                                                        components={{ DropdownIndicator }}
                                                        classNamePrefix="insurance-main-select"
                                                        className="payment-frequency-option"
                                                        onChange={this.handleChangePaymentFrequencyOption}
                                                        options={PAYMENT_FREQUENCY_OPTIONS}
                                                        isSearchable={false}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} className="summaryCardAdditionalOptionsBlock">
                                                 <div className="summaryCardAdditionalOptions">
                                                     { selected_plan.repatriation_liability_text }

                                                     <Tooltip
                                                         tooltipId="get_plan_liab"
                                                         tooltipType="light"
                                                         tooltipEffect="solid"
                                                         tooltipIconSize={15}
                                                         tooltipIconTopPosition={3}
                                                         tooltipIconOffset={3}
                                                         tooltipContent={ selected_plan.repatriation_liability_tooltip_text }
                                                     />

                                                 </div>
                                            </Col>
                                        </Row>

                                    </div>
                                    <div className="summaryCardFooter">
                                        <div className="summaryCardTotalPrice">
                                            <Row>
                                                <Col xs={6} className="summaryCardTotalPriceTitle">
                                                    {
                                                        (process.env.REACT_APP_LANGUAGE === "EN")
                                                            ? <>{(PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value))[0].label} <Trans i18nKey="global_price_text"/><Trans i18nKey="global_space"/></>
                                                            : <><Trans i18nKey="global_price_text"/> {(PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value))[0].label.toLowerCase()}<Trans i18nKey="global_space"/></>
                                                    }

                                                </Col>
                                                <Col xs={6} className="summaryCardTotalPriceValue">
                                                    {
                                                        generateFormattedPrice(
                                                            calculateFinalPrice(
                                                                selected_plan.insurance_company,
                                                                this.props.payment_frequency_value,
                                                                (selected_plan.spreadsheet_rows) ? selected_plan.spreadsheet_rows.length : 0,
                                                                selected_plan.spreadsheet_rows,
                                                                selected_plan.coverage_types_included,
                                                                (selected_plan.deductible_selected_object) ? selected_plan.deductible_selected_object.value : 0,
                                                                (selected_plan.coinsurance_selected_object) ? selected_plan.coinsurance_selected_object.value : 100,
                                                                selected_plan.destination_zone,
                                                                selected_plan.company_guarantee_level,
                                                                this.props.currency,
                                                                insurees_data.destination_country
                                                            )["current_price"], this.props.currency)
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                </div>

                                <div className="summaryCard travelSummaryCard">
                                    <div className="summaryCardTitle"><Trans i18nKey="getPlanePage_your_star_title" />:</div>
                                    <div className="summaryCardBody">
                                        <div className="travelSummaryRow">
                                            <div className="travelSummaryName"><Trans i18nKey="getPlanePage_destination_title"/> :</div>
                                            <div className="travelSummaryValue">{(insurees_data) ? insurees_data.destination_country : "not selected"}</div>
                                        </div>
                                        <div className="travelSummaryRow">
                                            <div className="travelSummaryName"><Trans i18nKey="getPlanePage_length_stay_title"/> :</div>
                                            <div className="travelSummaryValue">{(insurees_data) ? INSURANCE_DURATION_OPTIONS.filter(option => option.value === insurees_data.insurance_duration)[0].label : "not selected"}</div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col className="getPlanPageRightPart" xs={8}>
                                <div className={`userDataContainer ${(this.state.form_validation_error_detected) ? "form-validation-error" : null }`}>

                                    {/*<div className="userDataCard personalInformationCard">*/}
                                    {/*    <div className="userDataCardTitle"><span className="icon-user-big"></span> Parlons de votre contrat</div>*/}
                                    {/*    <div className="userDataCardSubTitle"><Trans i18nKey="getPlanePage_your_contacts_subtitle"/></div>*/}

                                    {/*    <div className="insureesListContainer">*/}

                                    {/*        { this.renderInsureesPersonalInformationList() }*/}

                                    {/*    </div>*/}
                                    {/*    */}
                                    {/*</div>*/}
                                    <div className="userDataCard contactInformationCard">
                                        <div className="userDataCardTitle"><span className="icon-message-circle"></span> <Trans i18nKey={"getPlanePage_subscribe_form_title"}/></div>
                                        <div className="userDataCardSubTitle"><Trans i18nKey={"getPlanePage_subscribe_form_subtitle"}/></div>

                                        <Row>

                                            <Col xs={12}>
                                                <div className={`inputBox ${(textInputValidation(contact_information_object.full_name)) ? "is-valid-input" : "not-valid-input" }`}>
                                                    <label><Trans i18nKey={"getPlanePage_your_contacts_name"}/><span className="red">*</span></label>
                                                    <input
                                                        type="text"
                                                        placeholder={i18next.t("global_your_name_placeholder")}
                                                        defaultValue={contact_information_object.full_name}
                                                        onChange={this.handleChangeContactInformationFullName}
                                                    />
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row>

                                            <Col xs={6}>
                                                <div className={`inputBox ${(emailInputValidation(contact_information_object.email)) ? "is-valid-input" : "not-valid-input" }`}>
                                                    <label><Trans i18nKey={"getPlanePage_subscribe_form_email"}/><span className="red">*</span></label>
                                                    <input
                                                        type="text"
                                                        placeholder={i18n.t("global_your_email_placeholder")}
                                                        defaultValue={contact_information_object.email}
                                                        onChange={this.handleChangeContactInformationEmail}
                                                    />
                                                </div>
                                            </Col>

                                            <Col xs={6}>
                                                <div className={`inputBox ${(contact_information_object.phone) ? "is-valid-input" : "not-valid-input" }`}>
                                                    <label><Trans i18nKey={"getPlanePage_subscribe_form_phone"}/></label>
                                                    <PhoneInput
                                                        country={'fr'}
                                                        placeholder="+33 1 09 75 83 51"
                                                        defaultValue={contact_information_object.phone}
                                                        onChange={this.handleChangeContactInformationPhone}
                                                        // isValid={(inputNumber, country, countries) => {
                                                        //
                                                        //     let baseNumberLength = country.format.replace(/\s+/g, '').replace(/[+()\-_]/g, '').length;
                                                        //     let inputNumberLength = inputNumber.length;
                                                        //
                                                        //     if ( baseNumberLength === inputNumberLength ) {
                                                        //         this.handleChangeContactInformationPhone(inputNumber);
                                                        //     } else {
                                                        //         this.handleChangeContactInformationPhone(null);
                                                        //     }
                                                        // }}
                                                    />
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row>

                                            <Col xs={12} className="dateInputCol">
                                                <div className={`inputBox ${(this.state.user_personal_information.date_of_subscription) ? "is-valid-input" : "not-valid-input" }`}>
                                                    <label><Trans i18nKey={"getPlanePage_your_contacts_date"}/><span className="red">*</span></label>
                                                    <DatePicker
                                                        minDate={moment().toDate()}
                                                        calendarClassName="insurance-main-datepicker"
                                                        locale={(process.env.REACT_APP_LANGUAGE === "FR") ? fr : (process.env.REACT_APP_LANGUAGE === "ES") ? es : null }
                                                        dateFormat="dd/MM/yyyy"
                                                        onFocus={(e) => e.target.readOnly = true}
                                                        placeholderText={moment(new Date()).format("DD/MM/YYYY")}
                                                        selected={this.state.user_personal_information.date_of_subscription}
                                                        onChange={this.handleChangeDateOfSubscription}
                                                    />
                                                </div>
                                            </Col>

                                        </Row>



                                        <Row>

                                            <Col xs={12}>
                                                <div className={`inputBox ${(textInputValidation(contact_information_object.message)) ? "is-valid-input" : null }`}>
                                                    <label><Trans i18nKey={"getPlanePage_subscribe_form_message"}/></label>
                                                    <textarea
                                                        defaultValue={contact_information_object.message}
                                                        onChange={this.handleChangeContactInformationMessage}
                                                    ></textarea>
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row className="bottomRow">
                                            <Col xs={5} className={`checkboxCol`}>
                                                <div className="checkboxColDiv checkbox-wrapper-13">
                                                    <Checkbox
                                                        onChange={this.handleChangeCheckbox}
                                                    />
                                                </div>
                                                <div className="checkboxColDiv">
                                                    <p>
                                                        <Trans i18nKey={"legal_checkbox"}/>
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col xs={7}>
                                                <button className="mainButtonStyle handleReceiveACall" onClick={this.handleReceiveACall}>
                                                    <Trans i18nKey={"resultPage_get_plan_button"}/> <span className="icon-big-arrow-right"></span>
                                                </button>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col xs={12} className="legalCol">
                                                <p>
                                                    <Trans i18nKey="legal_text"/> <a href={i18n.t("legal_link")} target="_blank"> <Trans i18nKey="legal_link_text"/></a>.
                                                </p>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>



                    {/*  MODALS PART  */}
                    { this.renderModalContent() }

                    {/*<Modal*/}
                    {/*    show={this.state.is_modal_visible}*/}
                    {/*    onHide={this.handleCloseModal}*/}
                    {/*    backdrop="static"*/}
                    {/*    keyboard={false}*/}
                    {/*    dialogClassName="full-window-modal"*/}
                    {/*>*/}
                    {/*    <Modal.Header closeButton>*/}
                    {/*        <Container className="defaultContainer">*/}
                    {/*            <button className="close-modal-button" variant="secondary" onClick={this.handleCloseModal}>*/}
                    {/*                <span className="icon-big-arrow-left"></span>*/}
                    {/*                <span className="close-modal-button-title">Retour</span>*/}
                    {/*            </button>*/}
                    {/*            <Modal.Title>Détails de votre offre</Modal.Title>*/}
                    {/*        </Container>*/}
                    {/*    </Modal.Header>*/}
                    {/*    <Modal.Body>*/}
                    {/*        <ViewPlan />*/}
                    {/*    </Modal.Body>*/}
                    {/*</Modal>*/}

                </div>

            )

        } else {

            return (

                <Container>
                    <Trans i18nKey="global_no_plans_found"/>
                </Container>

            )

        }




    }


}

export default withRouter(connect(
    state => ({
        insurees_data: state.main_form_data.insurees_data,
        get_plan_data: state.get_plan_data.plan_data,
        view_plan_data: state.view_plan_data.local_plan_data,
        result_insurance_plans_array: state.result_insurance_plans.result_insurance_plans_array,
        payment_frequency_value: state.payment_frequency.payment_frequency_value,
        server_plan_data: state.view_plan_data.server_plan_data,
        currency: state.currency_options.currency,
    }),
    dispatch => ({
        onSaveInsurancePlanDataForViewPlan: (plan_data_object) => {
            dispatch(saveInsurancePlanDataForViewPlan(plan_data_object));
        },
        onChangePaymentFrequencyValue: (new_payment_frequency_value) => {
            dispatch(changePaymentFrequencyValue(new_payment_frequency_value));
        },
        onSendSelectedInsurancePlanToEmail: (data_for_sending) => {
            dispatch(sendSelectedInsurancePlanToEmail(data_for_sending));
        },
        onFetchInsurancePlanExtendedData: (insurance_company, company_guarantee_level, data_type_for_USA_MSH) => {
            dispatch(fetchInsurancePlanExtendedData(insurance_company, company_guarantee_level, data_type_for_USA_MSH));
        }
    })
)(GetPlanPage));

