import React, {useEffect} from 'react';
import './App.css';
import Header from "./components/Header/Header";
import {initGA, GAevent} from "./global-app-elements/ga-functions";
import './localization/styles/es_styles.css';
import './localization/styles/en_styles.css';

// import InsuranceForm from "./demo-app/InsuranceForm";

// INITIALIZATION GOOGLE ANALYTICS
initGA();
// initialization of Google Tag Manager
GAevent();
require('dotenv').config()



const App = ({children}) => (

    <div className={`expatAssuranceApp` +" "+ process.env.REACT_APP_LANGUAGE+"_reset_style_class"}>
        <Header />

        <div className="pageBody">

            {children}

        </div>

    </div>

);

export default App;
