import React, { Component } from 'react';
import './ComparesPlans.css';
import {Col, Container, Row, Spinner, Table} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    calculateFinalPrice,
    renderGuaranteeLevelNameTag,
    renderInsuranceCompanyLogo,
    generateFormattedPrice
} from "../../../global-app-elements/global_helper_functions";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { PAYMENT_FREQUENCY_OPTIONS } from "../../../global-app-elements/global_selects_options";
import Select, {components} from "react-select";
import {
    saveInsurancePlanDataForGetPlan,
    changePaymentFrequencyValue,
    fetchInsurancePlanExtendedData, multipleFetchingInsurancePlansExtendedData
} from "../../../actions/actions";
import {Trans} from "react-i18next";
import i18n from 'i18next';




const IF_VIEW_PLAN_LEVEL_IS_NON = (<span className="icon-close"></span>);


const cloneDeep = require('clone-deep');
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="icon-arrow-down"></span>
        </components.DropdownIndicator>
    );
};





class ComparesPlan extends Component {

    constructor(props) {

        super(props);

        this.state = {
            data_type_for_USA_MSH: 'DANS',
            table_width: 0,
            is_mobile_scroll_listener_active: false,
            is_mobile_horizontal_scroll_ending: false
        };

        this.handleGetPlan = this.handleGetPlan.bind(this);
        this.renderComparePlansAccordionItem = this.renderComparePlansAccordionItem.bind(this);
        this.renderOneColByOneRowCompareTable = this.renderOneColByOneRowCompareTable.bind(this);
        this.renderAllColsByOneRowCompareTable = this.renderAllColsByOneRowCompareTable.bind(this);
        this.handleChangePaymentFrequencyOption = this.handleChangePaymentFrequencyOption.bind(this);
        this.handleChangeDataTypeForUsaMsh = this.handleChangeDataTypeForUsaMsh.bind(this);
        this.renderTopColsOfComparePlansTable = this.renderTopColsOfComparePlansTable.bind(this);
        this.handleHorizontalScrollTableToRight = this.handleHorizontalScrollTableToRight.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.checkTableWidth = this.checkTableWidth.bind(this);

    }


    componentDidMount() {

        this.getInsurancePlansExtendedData();


        // FIXED HEADER DESKTOP//////////////////
        /////////////////////////////////////////
        // слухати скролл модалки compare , і фіксувати/відпускати хедер
        let compare_modal_object = document.getElementsByClassName("modal")[0];


        compare_modal_object.addEventListener('scroll', function(e) {

            let static_table_header_object = document.getElementById("staticTableHeader");
            let sticky_table_header_object = document.getElementById("stickyTableHeader");
            let scroll_to_top_button = document.getElementById("scrollToTopDynamicButton");
            let header_bottom_position_to_viewport = static_table_header_object.getBoundingClientRect().bottom;

            // IF FOR STICKY HEADER
            if ( header_bottom_position_to_viewport <= 0 ) {

                sticky_table_header_object.classList.add("is-visible");

            } else {

                sticky_table_header_object.classList.remove("is-visible");

            }

            // IF FOR BUTTON TO TOP
            if ( ( header_bottom_position_to_viewport + 100 ) <= 0 ) {

                scroll_to_top_button.classList.add("is-visible");

            } else {

                scroll_to_top_button.classList.remove("is-visible");

            }

        });


        // перевизначити ширину фіксованого хедера таблиці , якщо ресайзнули вікно
        window.addEventListener('resize', this.checkTableWidth);

    }


    componentDidUpdate(prevProps, prevState) {

        // перевизначити ширину фіксованого хедера таблиці , якщо щось оновилося
        this.checkTableWidth();


        // Якщо користувач змінив datatype_for_USA_MSH , то треба оновити view plan дані
        if (prevState.data_type_for_USA_MSH !== this.state.data_type_for_USA_MSH) {

            this.getInsurancePlansExtendedData();

        }


        // FIXED HEADER MOBILE//////////////////
        /////////////////////////////////////////
        // слухати скролл модалки compare , і фіксувати/відпускати хедер
        let compare_modal_object = document.getElementById("comparePlansContainer");
        let then = this;

        if ( compare_modal_object && !this.state.is_mobile_scroll_listener_active ) {

            compare_modal_object.addEventListener('scroll', function(e) {

                let static_table_header_object = document.getElementById("staticTableHeader"),
                    sticky_table_header_object = document.getElementById("stickyTableHeader"),
                    compare_table_container = document.getElementsByClassName("comparePlanDataGroup")[0],
                    header_bottom_position_to_viewport = static_table_header_object.getBoundingClientRect().bottom;



                let new_scroll_left = Math.round(e.target.scrollLeft),
                    offset_width = e.target.offsetWidth,
                    scroll_width = e.target.scrollWidth,
                    scroll_progress = parseInt(scroll_width - offset_width);

                if ( new_scroll_left > scroll_progress - 10 ) {

                    then.setState({ is_mobile_horizontal_scroll_ending: true });

                } else {

                    if ( prevState.is_mobile_scroll_listener_active !== then.state.is_mobile_horizontal_scroll_ending ) {

                        then.setState({ is_mobile_horizontal_scroll_ending: false });

                    }


                }





                // IF FOR STICKY HEADER
                if ( header_bottom_position_to_viewport <= 0 ) {

                    sticky_table_header_object.classList.add("is-visible");
                    compare_table_container.classList.add("is-sticky");

                } else {

                    sticky_table_header_object.classList.remove("is-visible");
                    compare_table_container.classList.remove("is-sticky");

                }

            });

            this.setState({ is_mobile_scroll_listener_active: true });

        }

    }


    componentWillUnmount() {

        window.removeEventListener('resize', this.checkTableWidth);

    }


    checkTableWidth() {

        let table_width = document.getElementsByClassName("comparePlanTableBlock")[0];

        if (table_width) {

            if ( table_width.offsetWidth !== this.state.table_width ) {

                this.setState({
                    table_width: table_width.offsetWidth
                });

            }

        }

    }


    handleGetPlan(plan_id) {

        let result_insurance_plans_array = cloneDeep(this.props.result_insurance_plans_array);

        let current_plan_array = result_insurance_plans_array.filter(option => option.plan_id === plan_id);

        this.props.onSelectInsurancePlanDataObject(current_plan_array[0]);

    }


    handleChangePaymentFrequencyOption(selected_option)  {

        this.props.onChangePaymentFrequencyValue(selected_option.value)

    }


    handleChangeDataTypeForUsaMsh(selected_option) {

        if ( selected_option.target.checked ) {

            this.setState({ data_type_for_USA_MSH: 'DANS' });

        } else {

            this.setState({ data_type_for_USA_MSH: 'HORS' });

        }

    }


    handleHorizontalScrollTableToRight() {

        document.getElementById('comparePlansContainer').scrollLeft += 80;

    }


    renderFixedTheadBlock(array_plans_for_compare) {

        let fix_table_row = [];

        array_plans_for_compare.forEach((item_plan, index) => {

            fix_table_row.push(

                <div style={{width: this.state.table_width/(array_plans_for_compare.length+1)+'px'}} key={index} className={`fix-head-td fix-head-td-${index+2}`}>
                    {renderGuaranteeLevelNameTag(item_plan.guarantee_level_EA_displayed)}
                    <div className="fix-head-img-block">
                        <span></span>
                        <img src={renderInsuranceCompanyLogo(item_plan.insurance_company)} alt={item_plan.insurance_company} />
                    </div>
                    <span className="planPriceInCompareTable">
                        {
                            generateFormattedPrice(
                                calculateFinalPrice(
                                    item_plan.insurance_company,
                                    this.props.payment_frequency_value,
                                    (item_plan.spreadsheet_rows) ? item_plan.spreadsheet_rows.length : 0,
                                    item_plan.spreadsheet_rows,
                                    item_plan.coverage_types_included,
                                    0,
                                    100,
                                    item_plan.destination_zone,
                                    item_plan.company_guarantee_level,
                                    (this.props.insurees_data.destination_country === "États-Unis d’Amérique") ? 'USD' : 'EUR',
                                    this.props.insurees_data.destination_country
                                ), (this.props.insurees_data.destination_country === "États-Unis d’Amérique") ? 'USD' : 'EUR' )
                        }
                    </span>
                </div>

            );

        });


        return (

            <div id="stickyTableHeader">
                <div style={{width: this.state.table_width/(array_plans_for_compare.length+1)+'px'}} className="fix-head-td fix-head-td-1"></div>
                {fix_table_row}
            </div>

        );

    }


    renderTheadOfComparePlansTable(array_plans_for_compare) {

        let table_row = [];

        array_plans_for_compare.forEach((item_plan, index) => {

            table_row.push(

                <th key={index}>
                    {renderGuaranteeLevelNameTag(item_plan.guarantee_level_EA_displayed)}
                    <img src={renderInsuranceCompanyLogo(item_plan.insurance_company)} alt={item_plan.insurance_company} />
                </th>

            );

        });


        return (

            <>

                <thead id="staticTableHeader">
                    <tr>
                        <th>
                            <div className="paymentFrequencyBlock">
                                <label><Trans i18nKey={"coveregePage_payment_type_title"}/></label>
                                <Select
                                    // defaultMenuIsOpen={true}
                                    value={ (this.props.payment_frequency_value) ? PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value) : null }
                                    components={{ DropdownIndicator }}
                                    classNamePrefix="insurance-main-select"
                                    className="payment-frequency-option"
                                    onChange={this.handleChangePaymentFrequencyOption}
                                    options={PAYMENT_FREQUENCY_OPTIONS}
                                    isSearchable={false}
                                />
                            </div>
                        </th>
                        {table_row}
                    </tr>
                </thead>

            </>

        );

    }


    renderTopColsOfComparePlansTable(array_plans_for_compare) {

        let table_row1 = [];
        let table_row2 = [];
        let table_row3 = [];
        let table_row4 = [];
        let table_row5 = [];
        let details_count = 0;

        array_plans_for_compare.forEach((item_plan, index) => {

            table_row1.push(

                <td key={index}>
                    <span className="planPriceInCompareTable">
                        {
                            generateFormattedPrice(
                                calculateFinalPrice(
                                    item_plan.insurance_company,
                                    this.props.payment_frequency_value,
                                    (item_plan.spreadsheet_rows) ? item_plan.spreadsheet_rows.length : 0,
                                    item_plan.spreadsheet_rows,
                                    item_plan.coverage_types_included,
                                    0,
                                    100,
                                    item_plan.destination_zone,
                                    item_plan.company_guarantee_level,
                                    this.props.currency,
                                    this.props.insurees_data.destination_country
                                )["current_price"], this.props.currency)
                        }
                    </span>
                    <button
                        className="mainButtonStyle getPlanButton"
                        onClick={this.handleGetPlan.bind(this, item_plan.plan_id)}><Trans i18nKey={"resultPage_get_plan_button"}/></button>
                </td>

            );

            table_row2.push(

                <td key={index}>{item_plan.coverage_sum}</td>

            );

            table_row4.push(

                <td key={index}>{item_plan.default_zone}</td>

            );

            table_row5.push(

                <td key={index}>{item_plan.repatriation_service}</td>

            );


            if (item_plan.insurance_company === "MSH" && this.props.insurees_data.destination_country === "États-Unis d’Amérique") {

                details_count++;

                table_row3.push(

                    <td key={index}>
                        <div className="detailGuaranteesTitle">{(this.state.data_type_for_USA_MSH === 'DANS') ? <strong><Trans i18nKey={"viewPlans_inside_the_health_title"}/></strong> : i18n.t("viewPlans_outside_the_health_title") }</div>
                        <div className="detailGuaranteesSwitcher">
                            <input
                                id={`switch${index}`}
                                type="checkbox"
                                checked={(this.state.data_type_for_USA_MSH === 'DANS') ? true : false }
                                onChange={this.handleChangeDataTypeForUsaMsh}
                            />
                            <label htmlFor={`switch${index}`}></label>
                        </div>
                    </td>

                );

            } else {

                table_row3.push(

                    <td key={index}></td>

                );

            }



        });


        return (
            <tbody>
                <tr>
                    <td>
                        {
                            (process.env.REACT_APP_LANGUAGE === "EN")
                                ? <>{(PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value))[0].label} <Trans i18nKey="global_price_text"/><Trans i18nKey="global_space"/></>
                                : <><Trans i18nKey="global_price_text"/> {(PAYMENT_FREQUENCY_OPTIONS.filter(option => option.value === this.props.payment_frequency_value))[0].label.toLowerCase()}<Trans i18nKey="global_space"/></>
                        }
                    </td>
                    {table_row1}
                </tr>
                <tr>
                    <td><Trans i18nKey={"compare_sum_insured"}/></td>
                    {table_row2}
                </tr>
                <tr>
                    <td><Trans i18nKey={"compare_waiting_periods"}/></td>
                    {table_row4}
                </tr>
                <tr>
                    <td><Trans i18nKey={"compare_repatriation_service"}/></td>
                    {table_row5}
                </tr>
                { (this.props.insurees_data.destination_country === "États-Unis d’Amérique" && details_count > 0 )
                    ?
                        <tr>
                            <td><Trans i18nKey={"compare_waiting_periods"}/>&nbsp;:</td>
                            {table_row3}
                        </tr>
                    : null }
            </tbody>
        );

    }


    renderOneColByOneRowCompareTable(current_level_values_key, current_level_name, current_coverage_level_to_match, array_plans_for_compare) {

        // дізнаюсь index поточного левела який відмальовуєтся в даний момент
        const getCurrentLevelIndex = function(arr, current_level_values_key) {

            for (let level_index = 0; level_index < arr.length; level_index += 1) {

                if (arr[level_index]['level_key'] === current_level_values_key) {

                    return level_index;

                }

            }

        }


        // разуємо кількість MERGE прапорців під ряд
        const getMergeFlagsAmount = function(arr, current_level_values_key) {

            let current_level_index = getCurrentLevelIndex(arr, current_level_values_key);
            let mErge_flag_count = 1;

            for (let i = current_level_index + 1; i < arr.length; i++) {

                if (arr[i].level_value === "MERGE") {

                    mErge_flag_count++;

                } else {

                    return mErge_flag_count;

                }

            }

            return mErge_flag_count;

        }


        // перевіряємо чи наступний лемент від поточного не MERGE
        const getNextLevelValue = function(arr, key) {

            for (var i = 0; i < arr.length; i++) {

                if (arr[i].level_key === key) {

                    return arr[i + 1] && arr[i + 1].level_value;

                }

            }

        };


        let one_row_array = [];

        // console.log(array_plans_for_compare);

        array_plans_for_compare.forEach((item_plan, index) => {
            // console.log(item_plan.coverage_levels);
            let current_coverage_levels_object = item_plan.coverage_levels.filter(option => option.level_name === current_level_name);
            let current_levels_values_object = current_coverage_levels_object[0].level_values;

            let current_levels_values_array = [];
            // let current_level_index = null;

            // перетворю об*єкт з бекенду в масив , щоб можна було по ньому зручніше бігати
            for (let level_key in current_levels_values_object) {

                current_levels_values_array.push({
                    level_key: level_key,
                    level_value: current_levels_values_object[level_key]
                })

            }


            let next_level_value = getNextLevelValue(current_levels_values_array, current_level_values_key);

            // console.log(item_plan.coverage_types_included);
            // console.log(current_coverage_level_to_match);

            if ( item_plan.coverage_types_included.includes(current_coverage_level_to_match) ) {

                if (current_levels_values_object[current_level_values_key] !== "MERGE") {


                    if (next_level_value === 'MERGE') {


                        let mErge_flags_amount = getMergeFlagsAmount(current_levels_values_array, current_level_values_key);


                        if (current_levels_values_object[current_level_values_key] === "NON") {

                            one_row_array.push(<td key={index} rowSpan={mErge_flags_amount} className="non-value">{IF_VIEW_PLAN_LEVEL_IS_NON}</td>);

                        } else {

                            one_row_array.push(<td key={index} rowSpan={mErge_flags_amount}>{current_levels_values_object[current_level_values_key]}</td>);

                        }

                    } else {

                        if (current_levels_values_object[current_level_values_key] === "NON") {

                            one_row_array.push(<td key={index} className="non-value">{IF_VIEW_PLAN_LEVEL_IS_NON}</td>);

                        } else {

                            one_row_array.push(<td key={index}>{current_levels_values_object[current_level_values_key]}</td>);

                        }

                    }

                }

            } else {

                one_row_array.push(<td key={index} className="non-value">{IF_VIEW_PLAN_LEVEL_IS_NON}</td>);

            }

        });


        return one_row_array;

    }


    renderAllColsByOneRowCompareTable(current_level_name, current_level_values, current_coverage_level_to_match, array_plans_for_compare) {

        let result_levels_array = [];

        for (const level_values_key in current_level_values) {

            result_levels_array.push(

                <tr key={level_values_key}>
                    <td>{level_values_key}</td>
                    {this.renderOneColByOneRowCompareTable(level_values_key, current_level_name, current_coverage_level_to_match, array_plans_for_compare)}
                </tr>

            )

        }

        return result_levels_array;

    }


    renderComparePlansAccordionItem(array_plans_for_compare) {

        let newArr = [];
        let accordions_array = [];
        let base_coverage_levels = array_plans_for_compare[0].coverage_levels;

        // функція для видплення одинакових елементів з масиву
        Array.prototype.unique = function() {
            var a = this.concat();
            for(var i=0; i<a.length; ++i) {
                for(var j=i+1; j<a.length; ++j) {
                    if(a[i] === a[j])
                        a.splice(j--, 1);
                }
            }

            return a;
        };


        // об`єднання одинакових типів coverage_levels різних планів в один масив для подальшої фільтрації
        array_plans_for_compare.forEach((item_plan) => {

            let s = item_plan.coverage_types_included.split('+');
            newArr = newArr.concat(s).unique();


        });


        // вивід одного елемента акордеону в залежності від умов
        base_coverage_levels.forEach((item_coverage_levels, index) => {

            let item_coverage_level_to_match = item_coverage_levels.coverage_level_to_match;

            if ( newArr.includes(item_coverage_level_to_match) ) {

                accordions_array.push(

                    <AccordionItem key={index} className="comparePlansDataGroupItem" uuid={index}>
                        <AccordionItemHeading className="viewPlanDataGroupHead">
                            <AccordionItemButton>
                                {item_coverage_levels.level_name}
                                <div className="accordion-indicator">
                                    <span className="accordion-indicator-title"><Trans i18nKey={"viewPlans_show_less"}/></span>
                                    <span className="accordion-indicator-icon"></span>
                                </div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <Table>
                                <tbody>
                                    {
                                        this.renderAllColsByOneRowCompareTable(
                                            item_coverage_levels.level_name,
                                            item_coverage_levels.level_values,
                                            item_coverage_levels.coverage_level_to_match,
                                            array_plans_for_compare,
                                        )
                                    }
                                </tbody>
                            </Table>
                        </AccordionItemPanel>
                    </AccordionItem>

                )

            } else {

                accordions_array.push(

                    <AccordionItem key={index} className="viewPlanDataGroupItem not-available" uuid={index}>
                        <AccordionItemHeading className="viewPlanDataGroupHead">
                            <AccordionItemButton>
                                {item_coverage_levels.level_name}
                                <div className="accordion-indicator">Hide<span></span></div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <Table striped hover>
                                <tbody>
                                </tbody>
                            </Table>
                        </AccordionItemPanel>
                    </AccordionItem>

                )

            }

        });


        return accordions_array;

    }


    renderComparePlansServerData() {

        let compare_plans_local_array = this.props.compare_plans_local_array;
        let compare_plans_server_array = this.props.compare_plans_server_array;
        let combined_array_of_plans = [];

        // тестовий масив планів для COMPARE
        // let array_plans_for_compare = [
        //
        //     {
        //         "company": "April",
        //         "company_guarantee_level": "COMFORT",
        //         "guarantee_level_EA_displayed": "Comfort",
        //         "coverage_sum": " 1,500,000 €/$ ",
        //         "coverage_levels": [
        //             {
        //                 "coverage_level_to_match": "H",
        //                 "level_name": "Hospitalisation",
        //                 "level_values": {
        //                     "Delais d'attente": "Aucun",
        //                     "Chambre d'hopital": "Chambre privée standard",
        //                     "Hospitalisation de jour": "100%",
        //                     "Cancer": "100%",
        //                     "Dialyse": "100%",
        //                     "VIH": "Inclus avec les garanties Soins courants",
        //                     "Soins paliatifs": "100%",
        //                     "Hopital Psychiatrique": "jusqu’à 8 000 €/$/an,\n max 15 jour/an",
        //                     "Hospitalisation a Domicile": "100%"
        //                 }
        //             },
        //             {
        //                 "coverage_level_to_match": "RC",
        //                 "level_name": "Soins courants",
        //                 "level_values": {
        //                     "Delais d'attente": "Aucun",
        //                     "Plafond Annuel Soins courants": "Sans Plafond",
        //                     "Consultation Medecin": "10 visites par an couvertes à 100%. À partir de la 11ème visite, couverture jusqu’à 200 €/$ par visite",
        //                     "Consultation psychiatre": "jusqu’à 5 visites/an,\n maximum 200 €/$ par visite",
        //                     "Medicaments": "100%",
        //                     "Analyses": "100%",
        //                     "Radiologie/IRM": "IRM: 8000 euros",
        //                     "Kinesiterapie": "jusqu’à 4 000 €/$/an",
        //                     "Ostheopatie, chiropractie": "€1,000.00",
        //                     "Homeopathie, acupuncture, Medecine Chinoise": "MERGE",
        //                     "Bilan de Sante": "jusqu’à 800 €/$",
        //                     "Depistages Cancer": "100%"
        //                 }
        //             },
        //             {
        //                 "coverage_level_to_match": "M",
        //                 "level_name": "Maternité",
        //                 "level_values": {
        //                     "Delais d'attente": "Délai d’attente de 10 mois - Traitement infertilité 12 mois",
        //                     "Frais d’accouchement :\n consultations, soins pré et post natals, hospitalisation, chambre privée,\n frais de séjour, honoraires médicaux et chirurgicaux": " jusqu’à \n 6 000 €/$/ grossesse",
        //                     "Si accouchement Chirurgical": "jusqu’à 12 000 €/$/ grossesse ",
        //                     "Plafond complication": "Pas de Plafond",
        //                     "Traitement infertilite": " jusqu’à 1 500 €/$ par tentative "
        //                 }
        //             },
        //             {
        //                 "coverage_level_to_match": "OD",
        //                 "level_name": "Dentaire",
        //                 "level_values": {
        //                     "Delais d'attente": "Délai d’attente de 3 mois pour les soins dentaires, préventifs et de routine et 6 mois pour le traitement dentaire reconstructeur majeur et l’orthodontie.",
        //                     "Plafond Annuel": " 1ère & 2ème années : jusqu’à 2 000 €/$ \n À partir de la 3ème année : jusqu’à 3 000 €/$ ",
        //                     "Plafond par dent": "NON",
        //                     "Prothese-Implant": "Inclus",
        //                     "Orthodontie < 16 ans": "jusqu’à 1 000 €/$ par an, max. 3 ans "
        //                 }
        //             },
        //             {
        //                 "coverage_level_to_match": "OD",
        //                 "level_name": "Optique",
        //                 "level_values": {
        //                     "Delais d'attente": "Délai d’attente de 6 mois",
        //                     "Montant": " jusqu’à 500 €/$ ",
        //                     "Lentilles correctrices": "jusqu’à  300 €/$ même plafond",
        //                     "Traitement Laser": "Inclus même plafond"
        //                 }
        //             }
        //         ]
        //     },
        //
        //     {
        //         "company": "MSH",
        //         "company_guarantee_level": "QUARTZ",
        //         "guarantee_level_EA_displayed": "Basic",
        //         "coverage_sum": " 400 000 € /500 000$ ",
        //         "coverage_levels": [
        //             {
        //                 "coverage_level_to_match": "H",
        //                 "level_name": "Hospitalisation",
        //                 "level_values": {
        //                     "Delais d'attente": "Aucun",
        //                     "Chambre d'hopital": "Chambre semi-particulière 100 €/125 $ par jour",
        //                     "Hospitalisation de jour": "100%",
        //                     "Cancer": "100%",
        //                     "Dialyse": "100%",
        //                     "VIH": "100%",
        //                     "Soins paliatifs": " Max 10 000 €/12 500 $",
        //                     "Hopital Psychiatrique": "Exclus",
        //                     "Hospitalisation a Domicile": "NON"
        //                 }
        //             },
        //             {
        //                 "coverage_level_to_match": "RC",
        //                 "level_name": "Soins courants",
        //                 "level_values": {
        //                     "Delais d'attente": "Aucun",
        //                     "Plafond Annuel Soins courants": "€15,000.00 / 19,000.00 $",
        //                     "Consultation Medecin": "80 €/100 $ par acte ou consultation",
        //                     "Consultation psychiatre": "5 sessions par an",
        //                     "Medicaments": " 3 000 €/2 800 $ par an, si longue durée: 10 000 €/6 300 $ par an, avec un maximum pour la durée de l’adhésion de 50 000 €/63000 $ ",
        //                     "Analyses": "2 000 €/2 500 $ par an",
        //                     "Radiologie/IRM": "2 000 €/2 500 $ par an",
        //                     "Kinesiterapie": " 1 000 €/1 250 $ par an, limité à 12 séances par an",
        //                     "Ostheopatie, chiropractie": "8 séances, avec un maximum de 50 €/60 $ par séance ",
        //                     "Homeopathie, acupuncture, Medecine Chinoise": "3 séances, avec un maximum de 50 €/60 $ par séance",
        //                     "Bilan de Sante": "NON",
        //                     "Depistages Cancer": "NON"
        //                 }
        //             },
        //             {
        //                 "coverage_level_to_match": "M",
        //                 "level_name": "Maternité",
        //                 "level_values": {
        //                     "Delais d'attente": "Délai d’attente de 10 mois - Traitement infertilité 12 mois",
        //                     "Frais d’accouchement :\n consultations, soins pré et post natals, hospitalisation, chambre privée,\n frais de séjour, honoraires médicaux et chirurgicaux": " 3 500 €/4 400 $ par an",
        //                     "Si accouchement Chirurgical \n Plafond complication": "  7 000 €/8 800 $ par an ",
        //                     "Traitement Sterelite": "NON"
        //                 }
        //             },
        //             {
        //                 "coverage_level_to_match": "OD",
        //                 "level_name": "Dentaire",
        //                 "level_values": {
        //                     "Delais d'attente": " Délais d’attente : 3 mois pour soins dentaires et parodontologie, 6 mois pour prothèses, implants dentaires, greffes osseuses et chirurgie dentaire, 12 mois pour orthodontie",
        //                     "Plafond Annuel": "1 000 €/1 250 $",
        //                     "Plafond par dent": "€250.00",
        //                     "Prothese-Implant": "Inclus",
        //                     "Orthodontie < 16 ans": "NON"
        //                 }
        //             },
        //             {
        //                 "coverage_level_to_match": "OD",
        //                 "level_name": "Optique",
        //                 "level_values": {
        //                     "Delais d'attente": "Délai d’attente de 6 mois",
        //                     "Montant": " 100 €/125 $",
        //                     "Lentilles correctrices": " 100 €/125 $ par an",
        //                     "Traitement Laser": "NON"
        //                 }
        //             }
        //         ]
        //     },
        //
        //     {
        //         "company": "Allianz",
        //         "company_guarantee_level": "PREMIUM",
        //         "guarantee_level_EA_displayed": "VIP",
        //         "coverage_sum": " 3,000,000 €/$",
        //         "coverage_levels": [
        //             {
        //                 "coverage_level_to_match": "H",
        //                 "level_name": "Hospitalisation",
        //                 "level_values": {
        //                     "Delais d'attente": "Aucun",
        //                     "Chambre d'hopital": "Chambre particulière jusqu’à 350 € par jour, 45 jours max. Chambre semi-privée 100% des frais réels ",
        //                     "Hospitalisation de jour": "100%",
        //                     "Cancer": "100%",
        //                     "Dialyse": "100%",
        //                     "VIH": "100%",
        //                     "Soins paliatifs":  "100%",
        //                     "Hopital Psychiatrique": " Jusqu'à 10 000 €/$, soins de jour uniquement ",
        //                     "Hospitalisation a Domicile": "100%"
        //                 }
        //             },
        //             {
        //                 "coverage_level_to_match": "RC",
        //                 "level_name": "Soins courants",
        //                 "level_values": {
        //                     "Delais d'attente": "Aucun",
        //                     "Plafond Annuel Soins courants": "Sans Plafond",
        //                     "Consultation Medecin": "100%",
        //                     "Consultation psychiatre": "10 scéances par an",
        //                     "Medicaments": "100% ",
        //                     "Analyses": "100%",
        //                     "Radiologie/IRM": "100%",
        //                     "Kinesiterapie": "1 000 €/$ par an",
        //                     "Ostheopatie, chiropractie": "100 % des frais réels 50 € par séance, 12 sceances max",
        //                     "Homeopathie, acupuncture, Medecine Chinoise": "MERGE",
        //                     "Bilan de Sante": " 1 200 €/$ par an",
        //                     "Depistages Cancer": "MERGE"
        //                 }
        //             },
        //             {
        //                 "coverage_level_to_match": "M",
        //                 "level_name": "Maternité",
        //                 "level_values": {
        //                     "Delais d'attente": "Délai d’attente de 10 mois ",
        //                     "Frais d’accouchement :\n consultations, soins pré et post natals, hospitalisation, chambre privée,\n frais de séjour, honoraires médicaux et chirurgicaux": " jusqu’à 10 000 €/$/ grossesse ",
        //                     "Si accouchement Chirurgical": "MERGE",
        //                     "Plafond complication": "MERGE",
        //                     "Traitement infertilite": "MERGE"
        //                 }
        //             },
        //             {
        //                 "coverage_level_to_match": "OD",
        //                 "level_name": "Dentaire",
        //                 "level_values": {
        //                     "Delais d'attente": "Pas de Delais d'attente",
        //                     "Plafond Annuel": " 2500 €/$",
        //                     "Plafond par dent": " 550 €/$ ",
        //                     "Prothese-Implant": "3250 €/$ en plus",
        //                     "Orthodontie < 16 ans": "1700 €/$ par an pendant 3 ans "
        //                 }
        //             },
        //             {
        //                 "coverage_level_to_match": "OD",
        //                 "level_name": "Optique",
        //                 "level_values": {
        //                     "Delais d'attente": "Pas de délai d'attente",
        //                     "Montant": " 550 €/$ par an",
        //                     "Lentilles correctrices": "Inclus dans le  plafond",
        //                     "Traitement Laser": "1500 €/$ à vie"
        //                 }
        //             }
        //         ]
        //     }
        //
        // ];


        if ( compare_plans_server_array ) {

            // зливаємо лакльний і серверні масиви даних про план в один
            compare_plans_local_array.forEach((item, index) => {

                combined_array_of_plans.push({...item, ...compare_plans_server_array[index]});

            });


            return (

                <Row className="viewPlanServerDataRow comparePlanTableBlock">

                    <Col xs={12}>

                        {this.renderFixedTheadBlock(combined_array_of_plans)}

                        <Row className="greenTableRow">

                            <Table className="greenTable">
                                {this.renderTheadOfComparePlansTable(combined_array_of_plans)}
                                {this.renderTopColsOfComparePlansTable(combined_array_of_plans)}
                            </Table>

                        </Row>

                        <Row>

                            <Accordion
                                allowMultipleExpanded={true}
                                allowZeroExpanded={true}
                                preExpanded={[0, 1, 2, 3, 4]}
                                className="viewPlanDataGroup comparePlanDataGroup">

                                {this.renderComparePlansAccordionItem(combined_array_of_plans)}

                            </Accordion>

                        </Row>

                    </Col>

                </Row>

            )

        } else {

            return (

                <div className="loader" style={{textAlign: "center", marginTop: "150px"}}>
                    <Spinner animation="border" variant="success" />
                    {/*Loading...*/}
                </div>

            )

        }

    }


    getInsurancePlansExtendedData() {

        if ( this.props.compare_plans_local_array ) {

            let insurees_data = this.props.insurees_data;

            this.props.onMultipleFetchingInsurancePlansExtendedData(this.props.compare_plans_local_array, insurees_data, this.state.data_type_for_USA_MSH, this.props.insurees_data.destination_country);

        }

    }


    scrollToTop() {

        let compare_modal_object = document.getElementsByClassName("modal")[0];

        compare_modal_object.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

    }



    render() {

        let compare_plans_server_array = this.props.compare_plans_server_array;
        let compare_plans_local_array = this.props.compare_plans_local_array;

        if ( compare_plans_server_array && compare_plans_local_array.length > 0 ) {

            return (

                <div className="viewPlan">

                    <Container id="comparePlansContainer" className="defaultContainer viewPlanContainer comparePlansContainer">

                        { this.renderComparePlansServerData() }

                        <div className={`mobileTableNavigation ${(this.state.is_mobile_horizontal_scroll_ending) ? 'is-hiding' : null }`}>
                            {/*<div className="mobileTableNavigationLeft">*/}
                            {/*    <button><span className="icon-thin-arrow-left"></span></button>*/}
                            {/*</div>*/}
                            <div className="mobileTableNavigationRight">
                                <button onClick={this.handleHorizontalScrollTableToRight}><span className="icon-thin-arrow-right"></span></button>
                            </div>
                        </div>

                    </Container>


                    {/* SCROLL TO TOP BUTTON */}
                    <button onClick={this.scrollToTop} id="scrollToTopDynamicButton">
                        <span className="icon-arrow-up"></span> <Trans i18nKey={"compare_to_top_button"}/>
                    </button>

                </div>

            )

        } else {

            return (

                <div className="loader" style={{textAlign: "center", marginTop: "150px"}}>
                    <Spinner animation="border" variant="success" />
                    {/*Loading...*/}
                </div>


            )

        }

    }


}

export default withRouter(connect(
    state => ({
        insurees_data: state.main_form_data.insurees_data,
        local_view_plan_data: state.view_plan_data.local_plan_data,
        server_view_plan_data: state.view_plan_data.server_plan_data,
        compare_plans_local_array: state.compare_plans_data.local_plans_data,
        compare_plans_server_array: state.compare_plans_data.server_plans_data,
        result_insurance_plans_array: state.result_insurance_plans.result_insurance_plans_array,
        payment_frequency_value: state.payment_frequency.payment_frequency_value,
        currency: state.currency_options.currency,
    }),
    dispatch => ({
        onSelectInsurancePlanDataObject: (plan_data_object) => {
            dispatch(saveInsurancePlanDataForGetPlan(plan_data_object));
        },
        onChangePaymentFrequencyValue: (new_payment_frequency_value) => {
            dispatch(changePaymentFrequencyValue(new_payment_frequency_value));
        },
        onFetchInsurancePlanExtendedData: (insurance_company, company_guarantee_level, data_type_for_USA_MSH) => {
            dispatch(fetchInsurancePlanExtendedData(insurance_company, company_guarantee_level, data_type_for_USA_MSH));
        },
        onMultipleFetchingInsurancePlansExtendedData: (insurance_company, company_guarantee_level, data_type_for_USA_MSH, destination_country) => {
            dispatch(multipleFetchingInsurancePlansExtendedData(insurance_company, company_guarantee_level, data_type_for_USA_MSH, destination_country));
        }
    })
)(ComparesPlan));
